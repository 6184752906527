import React from "react";
import Modal from "react-bootstrap/Modal";
import UpdateBooking from "./Booking";
import UpdateDelivery from "./Delivery";
import UpdateExpense from "./Expense";
import DeleteBooking from "../delete/DeleteBooking";
import DeleteDelivery from "../delete/DeleteDelivery";
import DeleteExpense from "../delete/DeleteExpense";
import SearchByNumber from "../status/SearchByNumber";
import SearchByEmployeeName from "../status/SearchByEmployeeName";

const UpdateStatus = ({ show, close, value, info, check }) => {
  if (value === "Update Booking") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">{value.toUpperCase()}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateBooking bookingInfo={info} close={close} />
        </Modal.Body>
      </Modal>
    );
  } else if (value === "Update Delivery") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">{value.toUpperCase()}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateDelivery deliveryInfo={info} close={close} check={check} />
        </Modal.Body>
      </Modal>
    );
  } else if (value === "Update Expense") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">{value.toUpperCase()}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateExpense expenseInfo={info} close={close} />
        </Modal.Body>
      </Modal>
    );
  } else if (value === "Delete Booking") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">{value.toUpperCase()}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeleteBooking bookingInfo={info} close={close} />
        </Modal.Body>
      </Modal>
    );
  } else if (value === "Delete Delivery") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">{value.toUpperCase()}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeleteDelivery deliveryInfo={info} close={close} />
        </Modal.Body>
      </Modal>
    );
  } else if (value === "Delete Expense") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">{value.toUpperCase()}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeleteExpense expenseInfo={info} close={close} />
        </Modal.Body>
      </Modal>
    );
  } else if (value === "Searched By Number") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header>
          <Modal.Title className="modal-titles">
            <h5 className="model-status-info-details">
              {value.toUpperCase()}-: {info}
            </h5>
            <div>
              <i class="bi bi-x-circle-fill icon-close-xy" onClick={close}></i>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SearchByNumber search={info} />
        </Modal.Body>
      </Modal>
    );
  } else if (value === "Searched By Bill No") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header>
          <Modal.Title className="modal-titles">
            <h5 className="model-status-info-details">
              {value.toUpperCase()}-: {info}
            </h5>
            <div>
              <i class="bi bi-x-circle-fill icon-close-xy" onClick={close}></i>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SearchByNumber search={info} value={value} />
        </Modal.Body>
      </Modal>
    );
  } else if (value === "Employee Name") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header>
          <Modal.Title className="modal-titles">
            <h5 className="model-status-info-details">
              {value.toUpperCase()}-: {info.toUpperCase()}
            </h5>
            <div>
              <i class="bi bi-x-circle-fill icon-close-xy" onClick={close}></i>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SearchByEmployeeName search={info} />
        </Modal.Body>
      </Modal>
    );
  }
};

export default UpdateStatus;
