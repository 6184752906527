import moment from "moment";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/esm/Stack";
import Form from "react-bootstrap/Form";
import "./css/style.css";
import API from "../../../services/API";
import { useAuthDispatch, useAuthState } from "../../../context/context";
import auth from "../../../constant";
import Loader from "../../loder/Loader";
import UpdateStatus from "../update/Update";

const AddDelivery = ({ close }) => {
  const [warning, setWarning] = useState("");
  const [total, setTotal] = useState(0);
  const [booking, setBooking] = useState(0);
  const [infoDeliveryTotal, setInfoDeliveryTotal] = useState([]);
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const [success, setSuccess] = useState("");
  const [check, setCheck] = useState(false);
  const deliveryInfo = [];
  const deliveryData = JSON.parse(localStorage.getItem("lookupData"));
  if (deliveryData) {
    deliveryData?.map((v) => {
      if (v?.deliveryDetails?.length > 0) {
        deliveryInfo.push([v?.deliveryDetails]);
      }
    });
  }
  const deliver = deliveryInfo.flat();

  const lastDate = state.deliveryInfo[0]?.date;
  const [delivery, setDelivery] = useState({
    billNo: "",
    date: moment(lastDate).format("YYYY-MM-DD"),
    amount: "",
    qty: "",
    amountType: "cash",
  });
  const deleryHandler = (events) => {
    if (events.target.name === "amount") {
      events.target.value = events.target.value.replace(/^0/, "");
    }
    const { name, value } = events.target;
    setDelivery({ ...delivery, [name]: value });
  };
  const [otherAmount, setOtherAmount] = useState({
    billNo: "other-amounts",
    date: moment(lastDate).format("YYYY-MM-DD"),
    amount: "",
    qty: "",
    amountType: "cash",
    remarks: "",
  });
  const otherAmountHandler = (events) => {
    if (events.target.name === "amount") {
      events.target.value = events.target.value.replace(/^0/, "");
    }
    const { name, value } = events.target;
    setOtherAmount({ ...otherAmount, [name]: value });
  };
  const amountTypes = [
    {
      name: "cash",
      value: "cash",
    },
    {
      name: "atm",
      value: "atm",
    },
    {
      name: "adib",
      value: "adib",
    },
  ];
  const { billNo, date, qty, amount, amountType } = delivery;
  const findById = () => {
    setWarning("");
    const deliveryFlat = deliver.flat();
    let sum = 0;
    const delinfo = deliveryFlat?.filter((del) => {
      if (del?.billNo === billNo) {
        sum += del.amount;
        return del;
      }
    });
    setInfoDeliveryTotal(delinfo);
    setTotal(sum);
    deliveryData?.filter((v) => {
      if (v?.billNo === billNo) {
        setBooking(v?.amount);
      }
    });
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
   // if (booking >= total + Number(amount)) {
      try {
        dispatch({ type: auth.delivery.DELIVERY_REQUEST });
        if (check) {
          const { data } = await API({
            method: "POST",
            url: "/api/auth-create-delivery",
            data: {
              billNo: otherAmount.billNo,
              date: otherAmount.date,
              amount: otherAmount.amount,
              amountType: otherAmount.amountType,
              remarks: otherAmount.remarks,
            },
          });
          if (data?.success) {
            dispatch({ type: auth.delivery.DELIVERY_SUCCESS });
            setSuccess(data?.message);
            setTimeout(() => {
              close();
              window.location.replace("/delivery");
            });
          }
        } else {
          const { data } = await API({
            method: "POST",
            url: "/api/auth-create-delivery",
            data: { billNo, date, qty, amount, amountType },
          });
          if (data?.success) {
            dispatch({ type: auth.delivery.DELIVERY_SUCCESS });
            setSuccess(data?.message);
            setTimeout(() => {
              close();
              window.location.replace("/delivery");
            });
          }
        }
      } catch (error) {
        dispatch({ type: auth.ERROR });
        console.log(error);
      }
    //} else {
     // setWarning("Check Bill No as Amount is getting overflow ");
   // }
  };

  //=====================================handle update booking
  const [value, setValue] = useState("");
  const [info, setInfo] = useState({});
  const [hide, setHide] = useState(false);
  const handleUpdateBooking = ({
    _id,
    billNo,
    amount,
    amountType,
    qty,
    date,
  }) => {
    setInfo({
      _id,
      billNo,
      amount,
      amountType,
      qty,
      date,
    });
    setValue("Update Delivery");
    setHide(!hide);
  };
  //===================================
  const handleClose = () => {
    setHide(false);
  };
  return (
    <>
      <Form onSubmit={onSubmitForm} className="alert-style">
        <div className="form-container">
          <div className="form-entry">
            {check ? (
              <>
                <Form.Group className="mb-3 d-flex-contanier">
                  <Form.Label>
                    Date{" "}
                    <span style={{ color: "red" }}>
                      <sub>&nbsp; *</sub>
                    </span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={otherAmount.date}
                    onChange={otherAmountHandler}
                  />
                </Form.Group>

                <Form.Group className="mb-3 d-flex-contanier">
                  <Form.Label>
                    Amount{" "}
                    <span style={{ color: "red" }}>
                      <sub>&nbsp; *</sub>
                    </span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="amount"
                    value={otherAmount.amount}
                    onChange={otherAmountHandler}
                    required
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Amount Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="amountType"
                    value={otherAmount.amountType}
                    onChange={otherAmountHandler}
                  >
                    {amountTypes?.map((v, i) => {
                      return (
                        <>
                          <option key={i} value={v.value}>
                            {v.name.toUpperCase()}
                          </option>
                        </>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Remarks</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="remarks"
                    value={otherAmount.remarks}
                    onChange={otherAmountHandler}
                  />
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group className="mb-3 d-flex-contanier">
                  <Form.Label>
                    Bill No{" "}
                    <span style={{ color: "red" }}>
                      <sub>&nbsp; *</sub>
                    </span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="billNo"
                    value={billNo}
                    onChange={deleryHandler}
                    onBlur={findById}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3 d-flex-contanier">
                  <Form.Label>
                    Date{" "}
                    <span style={{ color: "red" }}>
                      <sub>&nbsp; *</sub>
                    </span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={date}
                    onChange={deleryHandler}
                  />
                </Form.Group>

                <Form.Group className="mb-3 d-flex-contanier">
                  <Form.Label>
                    Amount{" "}
                    <span style={{ color: "red" }}>
                      <sub>&nbsp; *</sub>
                    </span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="amount"
                    value={amount}
                    onChange={deleryHandler}
                    required
                  />
                </Form.Group>

                {infoDeliveryTotal?.length !== 0 ? (
                  <>
                    <ul className="info-alert-total-delivery">
                      {infoDeliveryTotal &&
                        infoDeliveryTotal.map((v) => {
                          const { _id, billNo, amount, amountType, qty, date } =
                            v;
                          return (
                            <>
                              <div className="total-alert-delivery-info">
                                <li className="update-add-delivery">
                                  <p
                                    onClick={() =>
                                      handleUpdateBooking({
                                        _id,
                                        billNo,
                                        amount,
                                        amountType,
                                        qty,
                                        date,
                                      })
                                    }
                                  >
                                    <span> Bill-No :-</span> {v?.billNo}
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    <span> Date :- </span>
                                    {moment(v.date).format("DD/MM/YYYY")}
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    <span> Amount :-</span> {v.amount}
                                  </p>
                                </li>

                                <li>
                                  <p>
                                    <span> Status :-</span>{" "}
                                    {v.amountType.toUpperCase()}
                                  </p>
                                </li>
                              </div>
                              <hr className="divider-delivery" />
                            </>
                          );
                        })}
                      <div className="delivery-status">
                        <li>
                          <span>Total Booking</span> {booking}
                        </li>
                        <li>
                          <span>Total Delivery</span> {total}
                        </li>
                        <hr className="divider-delivery" />
                        {booking === total + Number(amount) ? (
                          <i className="bi bi-check-circle-fill"></i>
                        ) : (
                          <li
                            style={
                              booking > total + Number(amount)
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >
                            <span>Status</span>{" "}
                            {Math.abs(booking - (total + Number(amount)))}
                          </li>
                        )}
                      </div>
                    </ul>
                  </>
                ) : null}

                <Form.Group>
                  <Form.Label>Amount Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="amountType"
                    value={amountType}
                    onChange={deleryHandler}
                  >
                    {amountTypes?.map((v, i) => {
                      return (
                        <>
                          <option key={i} value={v.value}>
                            {v.name.toUpperCase()}
                          </option>
                        </>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </>
            )}
            <Form.Check
              inline
              label="Other Amounts"
              name="group1"
              type={"checkbox"}
              className="mb-3"
              onChange={(e) => setCheck(e.target.checked)}
            />
            <p style={{ color: "green" }}>{success}</p>
            <p style={{ color: "red", textAlign: "center" }}>{warning}</p>
            <Stack>
              <Button
                variant="primary"
                size="sm"
                type="submit"
                className="btn-status"
              >
                {state?.loading ? <Loader /> : "Delivered"}
              </Button>
            </Stack>
          </div>
        </div>
      </Form>
      <UpdateStatus
        show={hide}
        value={value}
        close={handleClose}
        info={info}
        check={"conditional refresh"}
      />
    </>
  );
};

export default AddDelivery;
