import { Route, Routes } from "react-router-dom";
import Booking from "./components/info/Booking";
import Delivery from "./components/info/Delivery";
import Expense from "./components/info/Expense";
import Signin from "./components/auth/Signin";
import { useAuthDispatch } from "./context/context";
import PrivateRoute from "./components/routes/PrivateRoute";
import API from "./services/API";
import { useEffect } from "react";
import auth from "./constant";

function App() {
  const dispatch = useAuthDispatch();
  const fetchRecords = async () => {
    if (localStorage.getItem("test")) {
      dispatch({ type: auth.fetch.FETCH_REQUEST });
      const { data } = await API({
        method: "GET",
        url: "/api/booking-lookup",
      });
      if (data?.success) {
        const { booking } = data;
        localStorage.setItem("lookupData", JSON.stringify(booking));
        dispatch({ type: auth.fetch.FETCH_SUCCESS });
        localStorage.removeItem("test");
      }
      //====================================expense records
      const res = await API({
        method: "GET",
        url: "/api/auth-all-expense-records",
      });
      if (res?.data?.success) {
        localStorage.setItem(
          "allExpenseData",
          JSON.stringify(res?.data?.records)
        );
      }
      // localStorage.removeItem("test");
    }
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Booking />
            </PrivateRoute>
          }
        />
        <Route
          path="/delivery"
          element={
            <PrivateRoute>
              <Delivery />
            </PrivateRoute>
          }
        />
        <Route
          path="/expense"
          element={
            <PrivateRoute>
              <Expense />
            </PrivateRoute>
          }
        />
        <Route path="/signin" element={<Signin />} />
      </Routes>
    </>
  );
}

export default App;
