import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/esm/Stack";
import Button from "react-bootstrap/esm/Button";
import { useAuthDispatch, useAuthState } from "../../../context/context";
import auth from "../../../constant";
import API from "../../../services/API";
import Loader from "../../loder/Loader";
import { Link } from "react-router-dom";
import moment from "moment";
import UpdateStatus from "../update/Update";
const ExpenseInfo = () => {
  let totalAmount = 0;
  const groupResults = [];
  const dispatch = useAuthDispatch();
  const state = useAuthState();
  const [show, setShow] = useState(false);
  const [records, setRecords] = useState([]);
  const [filterExpense, setFilterExpense] = useState({
    startDate: "",
    endDate: "",
    dept: "",
    name: "",
    profitCheck: [""],
  });
  const handleFilterChange = (events) => {
    const { name, value } = events.target;
    setFilterExpense({
      ...filterExpense,
      [name]: value,
    });
  };
  const { startDate, endDate, name, dept } = filterExpense;
  const handleFilterExpense = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: auth.filter.FILTER_REQUEST });
      const { data } = await API({
        method: "post",
        url: "/api/auth-expense-range",
        data: { startDate, endDate, name, dept },
      });
      if (data?.success) {
        dispatch({ type: auth.filter.FILTER_SUCCESS });

        setRecords(data?.expenseRange);
      }
    } catch (error) {
      dispatch({ type: auth.ERROR });
      console.error(error);
    }
  };

  //=======================================================handle expense delete
  const [value, setValue] = useState("");
  const [info, setInfo] = useState({});
  const [hide, setHide] = useState(false);
  const hanldeExpenseDelete = ({
    _id,
    amount,
    date,
    dept,
    cat,
    name,
    remarks,
  }) => {
    setInfo({ _id, amount, date, dept, cat, name, remarks });
    setValue("Delete Expense");
    setHide(!hide);
  };

  //=============================================update expense
  const handleUpdateExpense = ({
    _id,
    amount,
    date,
    dept,
    cat,
    name,
    remarks,
  }) => {
    setInfo({ _id, amount, date, dept, cat, name, remarks });
    setValue("Update Expense");
    setHide(!hide);
  };

  //====================================================handle close
  //===================================
  const handleClose = () => {
    setHide(false);
  };
  //=================================group total
  records &&
    records.reduce((res, value) => {
      if (!res[value?.dept]) {
        res[value?.dept] = { status: value?.dept, amount: 0 };
        groupResults.push(res[value?.dept]);
      }
      res[value?.dept].amount += value.amount;
      return res;
    }, {});
  const showInfo = () => {
    setShow(!show);
  };

  return (
    <>
      <Form className="form-models" onSubmit={handleFilterExpense}>
        <Form.Group className=" range-style" controlId="formBasicEmail">
          <Form.Control
            type="date"
            placeholder="Enter email"
            className="first-child"
            required
            name="startDate"
            onChange={handleFilterChange}
            value={startDate}
          />
          <Form.Control
            type="date"
            placeholder="Enter email"
            className="last-child"
            required
            name="endDate"
            onChange={handleFilterChange}
            value={endDate}
          />
        </Form.Group>
        <Form.Text className="text-muted">
          <p style={{ color: "red" }}>Select start-date and end-end</p>
        </Form.Text>
        {/* //=====================Profit */}
        <div className="mb-3">
          <Form.Check
            inline
            label="Profit"
            name="dept"
            type={"radio"}
            className="form-radio"
            onChange={handleFilterChange}
            value="profit"
          />
          {dept === "profit" ? (
            <>
              {["checkbox"].map((type) => (
                <div key={`inline-${type}`} className="mb-3 ps-4">
                  <Form.Check
                    inline
                    label="Donation"
                    name="profitCheck"
                    type={type}
                    value="donation"
                    onChange={handleFilterChange}
                  />
                  <Form.Check
                    inline
                    label="Umar"
                    name="profitCheck"
                    type={type}
                    value="umar"
                    onChange={handleFilterChange}
                  />
                  <Form.Check
                    inline
                    label="Salman"
                    name="profitCheck"
                    type={type}
                    value="salman"
                    onChange={handleFilterChange}
                  />
                </div>
              ))}
            </>
          ) : null}

          <Form.Check
            inline
            label="Shop-Expense"
            name="dept"
            type={"radio"}
            className="form-radio"
            onChange={handleFilterChange}
            value="shop-expense"
          />
          {/* //=====================shop Expense */}
          {dept === "shop-expense" ? (
            <>
              {["checkbox"].map((type) => (
                <div key={`inline-${type}`} className="mb-3 ps-4">
                  <Form.Check inline label="Shop" name="group1" type={type} />
                  <Form.Check
                    inline
                    label="Donation"
                    name="group1"
                    type={type}
                  />
                  <Form.Check inline label="Visa" name="group1" type={type} />
                  <Form.Check
                    inline
                    label="Recharge"
                    name="group1"
                    type={type}
                  />
                  <Form.Check inline label="Salary" name="group1" type={type} />
                  <Form.Check
                    inline
                    label="Stationary"
                    name="group1"
                    type={type}
                  />
                  <Form.Check
                    inline
                    label="Electric"
                    name="group1"
                    type={type}
                  />
                  <Form.Check
                    inline
                    label="Delivery"
                    name="group1"
                    type={type}
                  />
                  <Form.Check inline label="Loss" name="group1" type={type} />
                  <Form.Check inline label="Cancel" name="group1" type={type} />
                </div>
              ))}
            </>
          ) : null}
          {/* //=====================piece Expense */}
          <Form.Check
            inline
            label="Piece-Expense"
            name="dept"
            type={"radio"}
            className="form-radio"
            onChange={handleFilterChange}
            value="piece-expense"
          />
          {dept === "piece-expense" ? (
            <>
              <p style={{ color: "red", paddingLeft: "50px" }}>
                F is known Fusoos
              </p>
              {["checkbox"].map((type) => (
                <div key={`inline-${type}`} className="mb-3 ps-4">
                  <Form.Check inline label="Shop" name="group1" type={type} />
                  <Form.Check
                    inline
                    label="Stitching"
                    name="group1"
                    type={type}
                  />
                  <Form.Check inline label="F_Work" name="group1" type={type} />
                  <Form.Check inline label="F_Buy" name="group1" type={type} />
                  <Form.Check inline label="Folak" name="group1" type={type} />

                  <Form.Check
                    inline
                    label="Khauwar"
                    name="group1"
                    type={type}
                  />
                  <Form.Check inline label="Tola" name="group1" type={type} />
                  <Form.Check inline label="Khaka" name="group1" type={type} />
                  <Form.Check
                    inline
                    label="Computer"
                    name="group1"
                    type={type}
                  />
                  <Form.Check
                    inline
                    label="Magribi"
                    name="group1"
                    type={type}
                  />
                  <Form.Check
                    inline
                    label="Qureshi"
                    name="group1"
                    type={type}
                  />
                  <Form.Check inline label="Talli" name="group1" type={type} />
                  <Form.Check inline label="Altor" name="group1" type={type} />
                  <Form.Check
                    inline
                    label="Out_Sti..."
                    name="group1"
                    type={type}
                  />
                  <Form.Check inline label="Sample" name="group1" type={type} />
                  <Form.Check
                    inline
                    label="Material"
                    name="group1"
                    type={type}
                  />
                  <Form.Check inline label="Jheek" name="group1" type={type} />
                </div>
              ))}
            </>
          ) : null}
        </div>

        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Employee Name..."
            className="mb-4"
            name="name"
            onChange={handleFilterChange}
            value={name}
          />
        </Form.Group>
        <Stack>
          <Button
            variant="primary"
            size="sm"
            type="submit"
            className="btn-status"
          >
            {state?.floading ? <Loader /> : "Filter"}
          </Button>
        </Stack>
      </Form>
      {records.length !== 0 ? (
        <>
          <div className="info-container">
            <div className="icon-info">
              <i
                className={
                  show
                    ? "bi bi-chevron-up show-hide"
                    : "bi bi-chevron-down show-hide"
                }
                onClick={showInfo}
              ></i>
            </div>

            {show ? (
              <div className="mb-5">
                <h5 className="title-info">
                  Info &nbsp;<span>{`From ${startDate} To  ${endDate}`}</span>
                </h5>

                {records &&
                  records?.map((data) => {
                    const { _id, amount, date, dept, cat, name, remarks } =
                      data;
                    return (
                      <div className="card-info">
                        <div className="card-items-info">
                          <div className="mt-1">
                            <div className="header-info">
                              <h5 className="card-heading-span">
                                Amount:- <span>{amount}</span>
                              </h5>
                              <Link
                                to="#"
                                onClick={() =>
                                  hanldeExpenseDelete({
                                    _id,
                                    amount,
                                    date,
                                    dept,
                                    cat,
                                    name,
                                    remarks,
                                  })
                                }
                              >
                                <i className="bi bi-trash3"></i>
                              </Link>
                            </div>
                          </div>
                          <div className="card-body">
                            <ul>
                              <li>
                                <p>Date</p>
                                <span>{moment(date).format("DD/MM/YYYY")}</span>
                              </li>
                              <li>
                                <p>Department</p>
                                <span>{dept.toUpperCase()}</span>
                              </li>
                              <li>
                                <p>Category</p>
                                <span>{cat.toUpperCase()}</span>
                              </li>
                              <li>
                                <p>Amount</p>
                                <span>{amount}</span>
                              </li>

                              <li>
                                <p>Name</p>
                                <span>{name.toUpperCase()}</span>
                              </li>
                              {remarks ? (
                                <li>
                                  <p>Remarks</p>
                                  <span>{remarks}</span>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                          <div className="card-info-footer">
                            <Link
                              to="#"
                              onClick={() =>
                                handleUpdateExpense({
                                  _id,
                                  amount,
                                  date,
                                  dept,
                                  cat,
                                  name,
                                  remarks,
                                })
                              }
                            >
                              Update Expense
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <hr className="divider-info" />
                <div className="card-info">
                  <div className="card-items-info">
                    <div className="mt-1">
                      <h5 className="card-heading-span mb-4">
                        Total Booking Amount
                      </h5>
                      <div className="card-body">
                        <ul>
                          {groupResults &&
                            groupResults.map((total, key) => {
                              totalAmount += total.amount;
                              return (
                                <li>
                                  <p>{total?.status.toUpperCase()}</p>
                                  <span>{total.amount}</span>
                                </li>
                              );
                            })}
                          <hr className="divider-info" />
                          <li>
                            <p className="card-heading-span">Total Amount</p>
                            <span className="card-heading-span">
                              {totalAmount}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      <UpdateStatus show={hide} value={value} close={handleClose} info={info} />
    </>
  );
};

export default ExpenseInfo;
