import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import UpdateStatus from "./../../models/update/Update";
const ExpenseSearch = () => {
  const [searchByNumber, setSearchByNumber] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const [searchPhone, setSearchPhone] = useState({
    search: "",
  });
  const searchByPhone = (events) => {
    if (events.target.name === "search") {
      events.target.value = events.target.value.replace(/^0/, "");
    }
    const { name, value } = events.target;
    setSearchPhone({ ...searchPhone, [name]: value });
  };
  const { search } = searchPhone;
  const phoneSearch = (e) => {
    e.preventDefault();
    try {
      if (search?.length > 0) {
        setShow(true);
        setSearchByNumber(search);
      } else {
        alert("Must be typed!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Form className="header-search-form" onSubmit={phoneSearch}>
        <Row className="g-0 justify-content-md-center">
          {windowWidth <= 767 ? (
            <>
              <Col className="col-10">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    type="search"
                    name="search"
                    value={search}
                    placeholder="Search by Employee Name..."
                    className="header-search-input w-100"
                    onChange={searchByPhone}
                  />
                </Form.Group>
              </Col>
              <Col className="col-2">
                <Button
                  variant="primary"
                  size="sm"
                  type="submit"
                  className="w-100 header-search-btn"
                >
                  <i className="bi bi-search"></i>
                </Button>
              </Col>
            </>
          ) : (
            <>
              <Col className="col-6">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    type="search"
                    name="search"
                    value={search}
                    placeholder="Search by Employee Name..."
                    className="header-search-input w-100"
                    onChange={searchByPhone}
                  />
                </Form.Group>
              </Col>
              <Col className="col-2">
                <Button
                  variant="primary"
                  size="sm"
                  type="submit"
                  className="w-100 header-search-btn"
                >
                  <i className="bi bi-search"></i>
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Form>
      <UpdateStatus
        show={show}
        close={handleClose}
        info={searchByNumber}
        value="Employee Name"
      />
    </>
  );
};

export default ExpenseSearch;
