import React, { useState } from "react";
import Layout from "../layout/Layout";
import UpdateStatus from "../models/update/Update";
import TotalAmount from "./TotalAmount";
import moment from "moment";
import { useAuthState } from "../../context/context";

const Expense = () => {
  //===============================================expense info
  const state = useAuthState();
  const expenseInfo = state.expenseInfo;
 

  //====================================updating
  const [value, setValue] = useState("");
  const [info, setInfo] = useState({});
  const [hide, setHide] = useState(false);
  const handleUpdateExpense = ({
    _id,
    amount,
    date,
    dept,
    cat,
    name,
    remarks,
  }) => {
    setInfo({ _id, amount, date, dept, cat, name, remarks });
    setValue("Update Expense");
    setHide(!hide);
  };
  const handleClose = () => {
    setHide(false);
  };
  return (
    <>
      <Layout>
        <div className="info-heading">
          <h1>Expense Info</h1>
        </div>
        <div className="card-container">
          {expenseInfo?.map((data) => {
            const { _id, amount, date, dept, cat, name, remarks } = data;
            return (
              <div className="card">
                <div className="card-items">
                  <div className="card-header">
                    <h5>
                      Amount:- <span>{data.amount}</span>
                    </h5>
                  </div>
                  <div className="card-body">
                    <ul>
                      <li>
                        <p>Date</p>
                        <span>{moment(data?.date).format("DD-MM-YYYY")}</span>
                      </li>
                      <li>
                        <p>Department</p>
                        <span>{data.dept.toUpperCase()}</span>
                      </li>
                      <li>
                        <p>Category</p>
                        <span>{data.cat.toUpperCase()}</span>
                      </li>
                      <li
                        onClick={() =>
                          handleUpdateExpense({
                            _id,
                            amount,
                            date,
                            dept,
                            cat,
                            name,
                            remarks,
                          })
                        }
                      >
                        <p>Name</p>
                        <span className="update-link">
                          {data.name.toUpperCase()}
                        </span>
                      </li>
                      {data.remarks && (
                        <li>
                          <p>Remarks</p>
                          <span>{data.remarks}</span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <hr />
        <TotalAmount />
      </Layout>
      <UpdateStatus show={hide} value={value} close={handleClose} info={info} />
    </>
  );
};

export default Expense;
