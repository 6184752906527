import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { useState } from "react";
import API from "../../../services/API";
import { useAuthDispatch, useAuthState } from "../../../context/context";
import auth from "../../../constant";
import Loader from "../../loder/Loader";
import { Link } from "react-router-dom";
import moment from "moment";
import UpdateStatus from "../update/Update";
function DeliveryInfo() {
  let totalAmount = 0;
  const groupResults = [];
  const dispatch = useAuthDispatch();
  const state = useAuthState();
  const [show, setShow] = useState(false);
  const [records, setRecords] = useState([]);
  const [filterDelivery, setFilterDelivery] = useState({
    startDate: "",
    endDate: "",
    amountType: "",
    startBillNo: "",
    endBillNo: "",
  });

  const handleFilterDelivery = (events) => {
    const { name, value } = events.target;
    setFilterDelivery({ ...filterDelivery, [name]: value });
  };
  const { startDate, endDate, amountType, startBillNo, endBillNo } =
    filterDelivery;
  const onSubmitFilterDelivery = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: auth.filter.FILTER_REQUEST });
      const { data } = await API({
        method: "POST",
        url: "/api/range-delivery",
        data: { startDate, endDate, amountType, startBillNo, endBillNo },
      });
      if (data?.success) {
        dispatch({ type: auth.filter.FILTER_SUCCESS });
        setRecords(data?.rangeDelivery);
      }
    } catch (error) {
      dispatch({ type: auth.ERROR });
      console.error(error);
    }
  };

  const showInfo = () => {
    setShow(!show);
  };

  //===================================
  const handleClose = () => {
    setHide(false);
  };
  //=================================group total
  records &&
    records.reduce((res, value) => {
      if (!res[value?.amountType]) {
        res[value?.amountType] = { status: value?.amountType, amount: 0 };
        groupResults.push(res[value?.amountType]);
      }
      res[value?.amountType].amount += value.amount;
      return res;
    }, {});
  //=========================================handle update delivery
  const [value, setValue] = useState("");
  const [info, setInfo] = useState({});
  const [hide, setHide] = useState(false);
  const hanldeDeliveryDelete = ({
    _id,
    billNo,
    amount,
    amountType,
    qty,
    date,
  }) => {
    setInfo({
      _id,
      billNo,
      amount,
      amountType,
      qty,
      date,
    });
    setValue("Delete Delivery");
    setHide(!hide);
  };

  //=====================================handle update booking
  const handleUpdateBooking = ({
    _id,
    billNo,
    amount,
    amountType,
    qty,
    date,
  }) => {
    setInfo({
      _id,
      billNo,
      amount,
      amountType,
      qty,
      date,
    });
    setValue("Update Delivery");
    setHide(!hide);
  };

  return (
    <>
      <Form className="form-models" onSubmit={onSubmitFilterDelivery}>
        <Form.Group className=" range-style" controlId="formBasicEmail">
          <Form.Text className="text-muted">
            <p style={{ color: "red" }}>Select start-date and end-end</p>
          </Form.Text>
          <Form.Control
            type="date"
            className="first-child"
            required
            name="startDate"
            value={startDate}
            onChange={handleFilterDelivery}
            style={{ marginBottom: "3px" }}
          />
          <Form.Control
            type="date"
            className="last-child"
            required
            name="endDate"
            value={endDate}
            onChange={handleFilterDelivery}
          />
        </Form.Group>
        <Form.Text className="text-muted">
          <p
            style={{
              color: "Blue",
              textAlign: "center",
              marginTop: "10px",
              marginBottom: "3px",
            }}
          >
            OR
          </p>
        </Form.Text>
        <Form.Text className="text-muted">
          <p style={{ color: "red" }}>Filter by Bill No</p>
        </Form.Text>
        <Form.Group className=" range-style" controlId="formBasicEmail">
          <Form.Control
            type="number"
            placeholder="Enter first bill No"
            className="first-child"
            name="startBillNo"
            value={startBillNo}
            onChange={handleFilterDelivery}
            style={{ marginBottom: "3px" }}
          />
          <Form.Control
            type="number"
            placeholder="Enter last bill No"
            className="last-child"
            name="endBillNo"
            value={endBillNo}
            onChange={handleFilterDelivery}
            style={{ marginBottom: "10px" }}
          />
        </Form.Group>
        {["radio"].map((type) => (
          <div key={`inline-${type}`} className="mb-3">
            <Form.Check
              inline
              label="AIDB"
              name="amountType"
              type={type}
              value="adib"
              onChange={handleFilterDelivery}
            />
            <Form.Check
              inline
              label="ATM"
              name="amountType"
              type={type}
              value="atm"
              onChange={handleFilterDelivery}
            />
            <Form.Check
              inline
              label="CASH"
              name="amountType"
              type={type}
              value="cash"
              onChange={handleFilterDelivery}
            />
            <Form.Check
              inline
              label="All"
              name="amountType"
              type={type}
              value=""
              onChange={handleFilterDelivery}
            />
          </div>
        ))}
        <Stack>
          <Button
            variant="primary"
            size="sm"
            type="submit"
            className="btn-status"
          >
            {state?.floading ? <Loader /> : "Filter"}
          </Button>
        </Stack>
      </Form>

      {records.length !== 0 ? (
        <>
          <div className="info-container">
            <div className="icon-info">
              <i
                className={
                  show
                    ? "bi bi-chevron-up show-hide"
                    : "bi bi-chevron-down show-hide"
                }
                onClick={showInfo}
              ></i>
            </div>

            {show ? (
              <div className="mb-5">
                <h5 className="title-info">
                  Info &nbsp;<span>{`From ${startDate} To  ${endDate}`}</span>
                </h5>

                {records &&
                  records?.map((data) => {
                    const { _id, billNo, amount, amountType, qty, date } = data;
                    return (
                      <div className="card-info">
                        <div className="card-items-info">
                          <div className="mt-1">
                            <div className="header-info">
                              <h5 className="card-heading-span">
                                Bill No:- <span>{billNo}</span>
                              </h5>
                              <Link
                                to="#"
                                onClick={() =>
                                  hanldeDeliveryDelete({
                                    _id,
                                    billNo,
                                    amount,
                                    amountType,
                                    qty,
                                    date,
                                  })
                                }
                              >
                                <i className="bi bi-trash3"></i>
                              </Link>
                            </div>
                          </div>
                          <div className="card-body">
                            <ul>
                              <li>
                                <p>Date</p>
                                <span>{moment(date).format("DD/MM/YYYY")}</span>
                              </li>

                              <li>
                                <p>Amount</p>
                                <span>{amount}</span>
                              </li>

                              <li>
                                <p>Amount Status</p>
                                <span>{amountType.toUpperCase()}</span>
                              </li>
                            </ul>
                          </div>
                          <div className="card-info-footer">
                            <Link
                              to="#"
                              onClick={() =>
                                handleUpdateBooking({
                                  _id,
                                  billNo,
                                  amount,
                                  amountType,
                                  qty,
                                  date,
                                })
                              }
                            >
                              Update Delivery
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <hr className="divider-info" />
                <div className="card-info">
                  <div className="card-items-info">
                    <div className="mt-1">
                      <h5 className="card-heading-span mb-4">
                        Total Booking Amount
                      </h5>
                      <div className="card-body">
                        <ul>
                          {groupResults &&
                            groupResults.map((total, key) => {
                              totalAmount += total.amount;
                              return (
                                <li>
                                  <p>{total?.status.toUpperCase()}</p>
                                  <span>{total.amount}</span>
                                </li>
                              );
                            })}
                          <hr className="divider-info" />
                          <li>
                            <p className="card-heading-span">Total Amount</p>
                            <span className="card-heading-span">
                              {totalAmount}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      <UpdateStatus show={hide} value={value} close={handleClose} info={info} />
    </>
  );
}

export default DeliveryInfo;
