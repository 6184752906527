import React, { useEffect, useState } from "react";
import "./css/style.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Search from "../header/search";

import List from "./List";
import Title from "../header/Title";
import Logo from "../header/Logo";

const Sidebar = () => {
  const [inactive, setInactive] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSidebar = () => {
    setInactive(!inactive);
  };

  return (
    <>
      {windowWidth > 767 && (
        <div className="logo-fixing">
          <Logo />
        </div>
      )}
      {windowWidth <= 767 && (
        <div className="title-icon">
          <Title />
          <i
            className={`bi menu-icon ${
              !inactive ? "inactive bi-text-right" : "bi-twitter-x"
            }`}
            onClick={handleSidebar}
          ></i>
        </div>
      )}
      <div className={`side-bar ${inactive ? "" : "sidebar"}`}>
        <aside>
          <div className="sidebar-content">
            <Row className="side-bar-search">
              <Col>{windowWidth < 767 ? <Search /> : <Title />}</Col>
            </Row>
            <Row>
              <Col>
                <List />
              </Col>
            </Row>
          </div>
        </aside>
      </div>
    </>
  );
};

export default Sidebar;
