import moment from "moment";
import React from "react";

const ExpenseExplorer = ({ year, status, month }) => {
  let total = 0;
  const expenseInfo = [];
  const expenseExplorer = JSON.parse(localStorage.getItem("allExpenseData"));
  const filterByLookup = (expenseExplorer) => {
    for (let i of expenseExplorer) {
      let date = new Date(i.date);
      if (
        (i.dept === status && date.getFullYear() === year && month === null) ||
        (i.dept === status &&
          date.getFullYear() === year &&
          date.getMonth() === month) ||
        (status === "total" && date.getFullYear() === year && month === null) ||
        (status === "total" &&
          date.getFullYear() === year &&
          date.getMonth() === month) ||
        (i.dept === status && year === null && month === null) ||
        (status === "total" && year === null && month === null)
      ) {
        expenseInfo.push(i);
      }
    }
  };
  filterByLookup(expenseExplorer);
  return (
    <>
      {expenseInfo?.length !== 0 ? (
        <div>
          {expenseInfo &&
            expenseInfo.map((element, key) => {
              total += element.amount;
              return (
                <>
                  <div className="total-booking-delivery">
                    <h6 className="delivery-info-details">Expense Info</h6>
                    <div className="total-bookingcontainer" key={key}>
                      <div className="total-property">Amount:-</div>
                      <div className="total-value">{element.amount}</div>
                      <div className="total-property">Date:-</div>
                      <div className="total-value">
                        {moment(element.date).format("DD/MM/YYYY")}
                      </div>
                      <div className="total-property">Department:-</div>
                      <div className="total-value">
                        {element.dept.toUpperCase()}
                      </div>
                      <div className="total-property">Category:-</div>
                      <div className="total-value">
                        {element.cat.toUpperCase()}
                      </div>
                      <div className="total-property">Name:-</div>
                      <div className="total-value">
                        {element.name.toUpperCase()}
                      </div>
                      {element?.remarks ? (
                        <>
                          {" "}
                          <div className="total-property">Remarks:-</div>
                          <div className="total-value">{element.reamrks}</div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </>
              );
            })}
          <br />
          <hr />
          <div className="total-booking-deliveries">
            <div className="total-footer-amount">
              <div className="total-p">Total Amount</div>
              <div className="total-v">{total}</div>
            </div>
          </div>
        </div>
      ) : (
        <p
          style={{ color: "red", textAlign: "center" }}
        >{`Oops No Records found of this ${status.toUpperCase()} records!`}</p>
      )}
    </>
  );
};

export default ExpenseExplorer;
