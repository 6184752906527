import moment from "moment";
import React from "react";
import { useAuthState } from "../../context/context";

const TotalAmount = () => {
  let advanceTotal = 0;
  let totalBooking = 0;
  let bookingGroupResults = [];
  const state = useAuthState();
  const bookingInfo = state?.bookingInfo;
  const lastBookingDate = bookingInfo[0]?.date;
  bookingInfo?.map((value) => {
    if (value?.advance !== undefined) {
      advanceTotal += value?.advance;
    }
  });

  //=================================booking info

  bookingInfo &&
    bookingInfo.reduce((res, value) => {
      if (!res[value?.status]) {
        res[value?.status] = { status: value?.status, amount: 0 };
        bookingGroupResults.push(res[value?.status]);
      }
      res[value?.status].amount += value.amount;
      return res;
    }, {});
  //==============================delivery info
  let totalDelivery = 0;
  const deliveryGroupResuls = [];
  const deliveryInformation = state?.deliveryInfo;
  const lastDeliveryDate = deliveryInformation[0]?.date;
  //============================group filter

  deliveryInformation &&
    deliveryInformation.reduce((res, value) => {
      if (!res[value?.amountType]) {
        res[value?.amountType] = { status: value?.amountType, amount: 0 };
        deliveryGroupResuls.push(res[value?.amountType]);
      }
      res[value?.amountType].amount += value.amount;
      return res;
    }, {});

  //===============================================expense info
  let totalExpense = 0;

  const expenseGroupResults = [];
  const expenseInfo = state?.expenseInfo;
  const lastExpenseDate = expenseInfo[0]?.date;
  //================================group filter
  expenseInfo &&
    expenseInfo.reduce((res, value) => {
      if (!res[value?.dept]) {
        res[value?.dept] = { status: value?.dept, amount: 0 };
        expenseGroupResults.push(res[value?.dept]);
      }
      res[value?.dept].amount += value.amount;
      return res;
    }, {});

  return (
    <>
      <div className="info-heading text-center my-2">
        <h1>Total Amount</h1>
      </div>

      <div className="card-container">
        <div className="card">
          <div className="card-items">
            <div className="card-header">
              <h5>Booking</h5>
            </div>
            <div className="card-body">
              <ul>
                <li>
                  <p>Date</p>
                  <span>{moment(lastBookingDate).format("DD-MM-YYYY")}</span>
                </li>
                {bookingGroupResults.map((total, key) => {
                  totalBooking += total.amount;
                  return (
                    <li key={key}>
                      <p>{total.status}</p>
                      <span>{total.amount}</span>
                    </li>
                  );
                })}
                <hr />
                <li>
                  <p>Total Advance</p>
                  <span>{advanceTotal}</span>
                </li>
                <li>
                  <p>Total Amount</p>
                  <span>{totalBooking}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* //==============================Delivery */}
        <div className="card">
          <div className="card-items">
            <div className="card-header">
              <h5>Delivery</h5>
            </div>
            <div className="card-body">
              <ul>
                <li>
                  <p>Date</p>
                  <span>{moment(lastDeliveryDate).format("DD-MM-YYYY")}</span>
                </li>
                {deliveryGroupResuls &&
                  deliveryGroupResuls.map((total, key) => {
                    totalDelivery += total.amount;
                    return (
                      <li>
                        <p>{total.status.toUpperCase()}</p>
                        <span>{total.amount}</span>
                      </li>
                    );
                  })}
                <hr />
                <li>
                  <p>Total Amount</p>
                  <span>{totalDelivery}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* //========================expense */}
        <div className="card">
          <div className="card-items">
            <div className="card-header">
              <h5>Expense</h5>
            </div>
            <div className="card-body">
              <ul>
                <li>
                  <p>Date</p>
                  <span>{moment(lastExpenseDate).format("DD-MM-YYYY")}</span>
                </li>
                {expenseGroupResults &&
                  expenseGroupResults.map((total, key) => {
                    totalExpense += total.amount;
                    return (
                      <li>
                        <p>{total.status.toUpperCase()}</p>
                        <span>{total.amount}</span>
                      </li>
                    );
                  })}
                <hr />
                <li>
                  <p>Total Amount</p>
                  <span>{totalExpense}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TotalAmount;
