const Title = () => {
  const shopName = localStorage.getItem("shopName");
  return (
    <>
      <h5 className="shop-title">{shopName?.toUpperCase()}</h5>
    </>
  );
};

export default Title;
