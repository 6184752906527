import moment from "moment";
import UpdateStatus from "../update/Update";
import { useState } from "react";
const SearchByNumber = ({ search, value }) => {
  const [hide, setHide] = useState(false);
  const [values, setValue] = useState("");
  const [info, setInfo] = useState({});
  const totalAmountBookingAndDelivery = [];
  const bookingRow = [];
  const searchNumber = JSON.parse(localStorage.getItem("lookupData"));

  if (value === "Searched By Bill No") {
    const filterNumber = searchNumber?.filter((v) => {
      if (v?.billNo === search) {
        const filter = v?.billNo;
        return filter;
      }
    });
    let bookingTotal = 0;
    let deliveryTotal = 0;
    if (filterNumber !== undefined) {
      for (let i of filterNumber) {
        bookingTotal += i.amount;

        if (i?.deliveryDetails?.length > 0) {
          bookingRow.push(i);
          let deliveryTotalAmount = 0;
          for (let j of i.deliveryDetails) {
            deliveryTotalAmount += j.amount;
            deliveryTotal += j.amount;
          }
          i.deliveryTotal = deliveryTotalAmount;
        } else {
          bookingRow.push(i);
        }
      }
    }
    totalAmountBookingAndDelivery.push(bookingTotal);
    totalAmountBookingAndDelivery.push(deliveryTotal);
  } else {
    const filterNumber = searchNumber?.filter((v) => {
      if (v?.phone?.indexOf(search) !== -1) {
        const filter = v?.phone.match(search);
        return filter;
      }
    });
    let bookingTotal = 0;
    let deliveryTotal = 0;
    if (filterNumber !== undefined) {
      for (let i of filterNumber) {
        bookingTotal += i.amount;

        if (i?.deliveryDetails?.length > 0) {
          bookingRow.push(i);
          let deliveryTotalAmount = 0;
          for (let j of i.deliveryDetails) {
            deliveryTotalAmount += j.amount;
            deliveryTotal += j.amount;
          }
          i.deliveryTotal = deliveryTotalAmount;
        } else {
          bookingRow.push(i);
        }
      }
    }
    totalAmountBookingAndDelivery.push(bookingTotal);
    totalAmountBookingAndDelivery.push(deliveryTotal);
  }
  //==================================booking update
  const handleUpdateBooking = ({
    _id,
    billNo,
    name,
    date,
    phone,
    countryCode,
    qty,
    amount,
    status,
  }) => {
    setInfo({
      _id,
      billNo,
      name,
      date,
      phone,
      countryCode,
      qty,
      amount,
      status,
    });
    setValue("Update Booking");
    setHide(!hide);
  };
  //=============================== Delivery Update
  const handleUpdateDelivery = ({
    _id,
    billNo,
    amount,
    amountType,
    qty,
    date,
  }) => {
    setInfo({
      _id,
      billNo,
      amount,
      amountType,
      qty,
      date,
    });
    setValue("Update Delivery");
    setHide(!hide);
  };
  const handleClose = () => {
    setHide(false);
  };
  return (
    <>
      {bookingRow?.length !== 0 ? (
        <div>
          {bookingRow?.map((element, key) => {
            const {
              _id,
              billNo,
              name,
              date,
              phone,
              countryCode,
              qty,
              amount,
              advance,
              amountType,
              status,
            } = element;
            return (
              <>
                <div className="total-booking-delivery" key={key}>
                  <h6 className="delivery-info-details">Booking Info</h6>
                  <div className="total-bookingcontainer">
                    <div
                      className="total-property"
                      onClick={() =>
                        handleUpdateBooking({
                          _id,
                          billNo,
                          name,
                          date,
                          phone,
                          countryCode,
                          qty,
                          amount,
                          status,
                        })
                      }
                    >
                      <span className="update-link">Bill No:-</span>
                    </div>
                    <div className="total-value">{element.billNo}</div>
                    <div className="total-property">Name:-</div>
                    <div className="total-value">
                      {element.name.toUpperCase()}
                    </div>
                    <div className="total-property">Phone:-</div>
                    <div className="total-value">{`${element.countryCode}-${element.phone}`}</div>
                    <div className="total-property">Date:-</div>
                    <div className="total-value">
                      {moment(element.date).format("DD/MM/YYYY")}
                    </div>
                    <div className="total-property">Quantity:-</div>
                    <div className="total-value">{element.qty}</div>
                    <div className="total-property">Amount:-</div>
                    <div className="total-value">{element.amount}</div>

                    {element?.advance ? (
                      <>
                        <div className="total-property">Advace:-</div>
                        <div className="total-value">{element.advance}</div>
                        <div className="total-property">Amount-Status:-</div>
                        <div className="total-value">
                          {element.amountType.toUpperCase()}
                        </div>
                      </>
                    ) : undefined}

                    <div className="total-property">Status:-</div>
                    <div className="total-value">
                      {element.status.toUpperCase()}
                    </div>
                    {element.deliveryDetails.length ? undefined : (
                      <>
                        {" "}
                        <div className="total-property alert-value">Alert</div>
                        <div className="total-value alert-value">
                          {element.amount}
                        </div>
                      </>
                    )}
                  </div>
                  {element.deliveryDetails.length ? <hr /> : undefined}
                  {element.deliveryDetails.length ? (
                    <h6 className="delivery-info-details">Delivery Info</h6>
                  ) : undefined}
                  {element.deliveryDetails.length
                    ? element.deliveryDetails.map((element, key) => {
                        const { _id, billNo, amount, amountType, qty, date } =
                          element;
                        return (
                          <>
                            <div className="total-delivery-container" key={key}>
                              <div
                                className="total-property"
                                onClick={() =>
                                  handleUpdateDelivery({
                                    _id,
                                    billNo,
                                    amount,
                                    amountType,
                                    qty,
                                    date,
                                  })
                                }
                              >
                                <span className="update-link">Bill No:-</span>
                              </div>
                              <div className="total-value">
                                {element.billNo}
                              </div>
                              <div className="total-property">Date:-</div>
                              <div className="total-value">
                                {moment(element.date).format("DD/MM/YYYY")}
                              </div>
                              <div className="total-property">Amount:-</div>
                              <div className="total-value">
                                {element.amount}
                              </div>
                              <div className="total-property">
                                Amount-Status:-
                              </div>
                              <div className="total-value">
                                {element.amountType.toUpperCase()}
                              </div>
                            </div>
                          </>
                        );
                      })
                    : undefined}
                  {element?.deliveryDetails.length ? (
                    <div className="total-amount">
                      <div className="total-property">Total Booking</div>
                      <div className="total-value">{element.amount}</div>
                      <div className="total-property">Total Delivery</div>
                      <div className="total-value">{element.deliveryTotal}</div>

                      {element.advance < element.deliveryTotal ? (
                        <>
                          {" "}
                          <div className="total-property alert-value">
                            Alert
                          </div>
                          <div className="total-value alert-value">
                            {(element.amount - element.deliveryTotal) * -1}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="total-property alert-success">
                            Alert
                          </div>
                          <div className="total-value alert-success">
                            {element.amount - element.deliveryTotal}
                          </div>
                        </>
                      )}
                    </div>
                  ) : undefined}
                </div>
              </>
            );
          })}

          <br />
          <hr />
          <div className="total-booking-deliveries">
            <div className="total-footer-amount">
              <div className="total-p">Total Booking</div>
              <div className="total-v">{totalAmountBookingAndDelivery[0]}</div>
              <div className="total-p">Total Delivery</div>
              <div className="total-v">{totalAmountBookingAndDelivery[1]}</div>
              {totalAmountBookingAndDelivery[0] >
              totalAmountBookingAndDelivery[1] ? (
                <>
                  <div className="total-p alert-value">Alert</div>
                  <div className="total-v alert-value">
                    {totalAmountBookingAndDelivery[0] -
                      totalAmountBookingAndDelivery[1]}
                  </div>
                </>
              ) : (
                <>
                  <div className="total-p alert-success">Alert</div>
                  <div className="total-v alert-success">
                    {(totalAmountBookingAndDelivery[0] -
                      totalAmountBookingAndDelivery[1]) *
                      -1}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <p
          style={{ color: "red", textAlign: "center" }}
        >{`Oops No Records found from this Number ${search}`}</p>
      )}
      <UpdateStatus
        show={hide}
        value={values}
        close={handleClose}
        info={info}
      />
    </>
  );
};

export default SearchByNumber;
