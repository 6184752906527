// const totalBookingDelivery = JSON.parse(localStorage.getItem("lookupData"));
const monthName = [
  "January",
  "Februray",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const date = new Date();
const year = date.getFullYear();
const month = date.getMonth();
const list = [
  { name: "Home", to: "/", id: "home", icon: "bi bi-house" },
  { name: "Delivery", to: "/delivery", id: "delivery", icon: "bi bi-cash" },
  {
    name: "Expense",
    to: "/expense",
    id: "expense",
    icon: "bi bi-layout-text-window-reverse",
  },
  {
    name: "Filter",
    to: "#",
    id: "status",
    subIcon: "bi bi-chevron-down",
    icon: "bi bi-filter",
    subList: [
      {
        name: "Booking",
        to: "#",
        id: "Filter Booking",
        icon: "bi bi-bookmark",
      },
      {
        name: "Delivery",
        to: "#",
        id: "Filter Delivery",
        icon: "bi bi-cash",
      },
      {
        name: "Expense",
        to: "#",
        id: "Filter Expense",
        icon: "bi bi-layout-text-window-reverse",
      },
    ],
  },
  {
    name: "Add Entry",
    to: "#",
    id: "addEntry",
    subIcon: "bi bi-chevron-down",
    icon: "bi bi-book",
    subList: [
      {
        name: "Booking",
        to: "#",
        id: "Booking Entry",
        icon: "bi bi-bookmark",
      },
      {
        name: "Delivery",
        to: "#",
        id: "Delivery Entry",
        icon: "bi bi-cash",
      },
      {
        name: "Expense",
        to: "#",
        id: "Expense Entry",
        icon: "bi bi-layout-text-window-reverse",
      },
    ],
  },
  {
    name: year,
    to: "#",
    id: "currentYear",
    icon: "bi bi-calendar-check",
    subIcon: "bi bi-chevron-down",
    subList: [
      {
        name: "Booking",
        to: "#",
        id: "Current year Booking",
        icon: "bi bi-bookmark",
        subIcon: "bi bi-chevron-down",
        details: [
          {
            name: "Cancel",
            to: "#",
            id: "booking cancel",
            value: 2234,
            dateYear: year,
            dateMonth: null,
          },
          {
            name: "Credit",
            to: "#",
            id: "booking credit",
            value: 765,
            dateYear: year,
            dateMonth: null,
          },
          {
            name: "Delivered",
            to: "#",
            id: "booking delivered",
            value: 8765,
            dateYear: year,
            dateMonth: null,
          },
          {
            name: "Stock",
            to: "#",
            id: "booking stock",
            value: 198765,
            dateYear: year,
            dateMonth: null,
          },
          {
            name: "Stitching",
            to: "#",
            id: "booking stitching",
            value: 765,
            dateYear: year,
            dateMonth: null,
          },
          {
            name: "Advance",
            to: "#",
            id: "booking advance",
            value: 76590,
            dateYear: year,
            dateMonth: null,
          },
          {
            name: "Total",
            to: "#",
            id: "booking total",
            value: 76589665,
            dateYear: year,
            dateMonth: null,
          },
        ],
      },
      {
        name: "Delivery",
        to: "#",
        id: "Current Year Delivery",
        icon: "bi bi-cash",
        subIcon: "bi bi-chevron-down",
        details: [
          {
            name: "ADIB",
            to: "#",
            id: "adib",
            value: 2234,
            dateYear: year,
            dateMonth: null,
          },
          {
            name: "ATM",
            to: "#",
            id: "atm",
            value: 765,
            dateYear: year,
            dateMonth: null,
          },
          {
            name: "CASH",
            to: "#",
            id: "cash",
            value: 8765,
            dateYear: year,
            dateMonth: null,
          },

          {
            name: "Total",
            to: "#",
            id: "delivery total",
            value: 76589665,
            dateYear: year,
            dateMonth: null,
          },
        ],
      },
      {
        name: "Expense",
        to: "#",
        id: "Current Year Expense",
        icon: "bi bi-layout-text-window-reverse",
        subIcon: "bi bi-chevron-down",
        details: [
          {
            name: "Piece-Expense",
            to: "#",
            id: "piece-expense",
            value: 2234,
            dateYear: year,
            dateMonth: null,
          },
          {
            name: "Profit",
            to: "#",
            id: "profit",
            value: 8765,
            dateYear: year,
            dateMonth: null,
          },
          {
            name: "Shop-Expense",
            to: "#",
            id: "shop-expense",
            value: 765,
            dateYear: year,
            dateMonth: null,
          },

          {
            name: "Total",
            to: "#",
            id: "expense total",
            value: 76589665,
            dateYear: year,
            dateMonth: null,
          },
        ],
      },
    ],
  },
  {
    name: year - 1,
    to: "#",
    id: "previousYear",
    icon: "bi bi-calendar-check",
    subIcon: "bi bi-chevron-down",
    subList: [
      {
        name: "Booking",
        to: "#",
        id: "previous year Booking",
        icon: "bi bi-bookmark",
        subIcon: "bi bi-chevron-down",
        details: [
          {
            name: "Cancel",
            to: "#",
            id: "booking cancel",
            value: 2234,
            dateYear: year - 1,
            dateMonth: null,
          },
          {
            name: "Credit",
            to: "#",
            id: "booking credit",
            value: 765,
            dateYear: year - 1,
            dateMonth: null,
          },
          {
            name: "Delivered",
            to: "#",
            id: "booking delivered",
            value: 8765,
            dateYear: year - 1,
            dateMonth: null,
          },
          {
            name: "Stock",
            to: "#",
            id: "booking stock",
            value: 198765,
            dateYear: year - 1,
            dateMonth: null,
          },
          {
            name: "Stitching",
            to: "#",
            id: "booking stitching",
            value: 765,
            dateYear: year - 1,
            dateMonth: null,
          },
          {
            name: "Advance",
            to: "#",
            id: "booking advance",
            value: 76590,
            dateYear: year - 1,
            dateMonth: null,
          },
          {
            name: "Total",
            to: "#",
            id: "booking total",
            value: 76589665,
            dateYear: year - 1,
            dateMonth: null,
          },
        ],
      },
      {
        name: "Delivery",
        to: "#",
        id: "previous Year Delivery",
        icon: "bi bi-cash",
        subIcon: "bi bi-chevron-down",
        details: [
          {
            name: "ADIB",
            to: "#",
            id: "adib",
            value: 2234,
            dateYear: year - 1,
            dateMonth: null,
          },
          {
            name: "ATM",
            to: "#",
            id: "atm",
            value: 765,
            dateYear: year - 1,
            dateMonth: null,
          },
          {
            name: "CASH",
            to: "",
            id: "cash",
            value: 8765,
            dateYear: year - 1,
            dateMonth: null,
          },

          {
            name: "Total",
            to: "#",
            id: "delivery total",
            value: 76589665,
            dateYear: year - 1,
            dateMonth: null,
          },
        ],
      },
      {
        name: "Expense",
        to: "#",
        id: "previous Year Expense",
        icon: "bi bi-layout-text-window-reverse",
        subIcon: "bi bi-chevron-down",
        details: [
          {
            name: "Piece-Expense",
            to: "#",
            id: "piece-expense",
            value: 2234,
            dateYear: year - 1,
            dateMonth: null,
          },
          {
            name: "Profit",
            to: "#",
            id: "profit",
            value: 8765,
            dateYear: year - 1,
            dateMonth: null,
          },
          {
            name: "Shop-Expense",
            to: "#",
            id: "shop-expense",
            value: 765,
            dateYear: year - 1,
            dateMonth: null,
          },

          {
            name: "Total",
            to: "#",
            id: "expense total",
            value: 76589665,
            dateYear: year - 1,
            dateMonth: null,
          },
        ],
      },
    ],
  },
  {
    name: monthName[month],
    to: "#",
    id: "currentMonth",
    icon: "bi bi-calendar-check",
    subIcon: "bi bi-chevron-down",
    subList: [
      {
        name: "Booking",
        to: "#",
        id: "Current Month Booking",
        icon: "bi bi-bookmark",
        subIcon: "bi bi-chevron-down",
        details: [
          {
            name: "Cancel",
            to: "#",
            id: "booking cancel",
            value: 2234,
            dateYear: year,
            dateMonth: month,
          },
          {
            name: "Credit",
            to: "#",
            id: "booking credit",
            value: 765,
            dateYear: year,
            dateMonth: month,
          },
          {
            name: "Delivered",
            to: "#",
            id: "booking delivered",
            value: 8765,
            dateYear: year,
            dateMonth: month,
          },
          {
            name: "Stock",
            to: "#",
            id: "booking stock",
            value: 198765,
            dateYear: year,
            dateMonth: month,
          },
          {
            name: "Stitching",
            to: "#",
            id: "booking stitching",
            value: 765,
            dateYear: year,
            dateMonth: month,
          },
          {
            name: "Advance",
            to: "#",
            id: "booking advance",
            value: 76590,
            dateYear: year,
            dateMonth: month,
          },
          {
            name: "Total",
            to: "#",
            id: "booking total",
            value: 76589665,
            dateYear: year,
            dateMonth: month,
          },
        ],
      },
      {
        name: "Delivery",
        to: "#",
        id: "Current Month Delivery",
        icon: "bi bi-cash",
        subIcon: "bi bi-chevron-down",
        details: [
          {
            name: "ADIB",
            to: "#",
            id: "adib",
            value: 2234,
            dateYear: year,
            dateMonth: month,
          },
          {
            name: "ATM",
            to: "#",
            id: "atm",
            value: 765,
            dateYear: year,
            dateMonth: month,
          },
          {
            name: "CASH",
            to: "#",
            id: "cash",
            value: 8765,
            dateYear: year,
            dateMonth: month,
          },

          {
            name: "Total",
            to: "#",
            id: "delivery total",
            value: 76589665,
            dateYear: year,
            dateMonth: month,
          },
        ],
      },
      {
        name: "Expense",
        to: "#",
        id: "Current Month Expense",
        icon: "bi bi-layout-text-window-reverse",
        subIcon: "bi bi-chevron-down",
        details: [
          {
            name: "Piece-Expense",
            to: "#",
            id: "piece-expense",
            value: 2234,
            dateYear: year,
            dateMonth: month,
          },
          {
            name: "Profit",
            to: "#",
            id: "profit",
            value: 8765,
            dateYear: year,
            dateMonth: month,
          },
          {
            name: "Shop-Expense",
            to: "#",
            id: "shop-expense",
            value: 765,
            dateYear: year,
            dateMonth: month,
          },

          {
            name: "Total",
            to: "#",
            id: "expense total",
            value: 76589665,
            dateYear: year,
            dateMonth: month,
          },
        ],
      },
    ],
  },
  {
    name: monthName[month - 1],
    to: "#",
    id: "previousMonth",
    icon: "bi bi-calendar-check",
    subIcon: "bi bi-chevron-down",
    subList: [
      {
        name: "Booking",
        to: "#",
        id: "previous Month Booking",
        icon: "bi bi-bookmark",
        subIcon: "bi bi-chevron-down",
        details: [
          {
            name: "Cancel",
            to: "#",
            id: "booking cancel",
            value: 2234,
            dateYear: year,
            dateMonth: month - 1,
          },
          {
            name: "Credit",
            to: "#",
            id: "booking credit",
            value: 765,
            dateYear: year,
            dateMonth: month - 1,
          },
          {
            name: "Delivered",
            to: "#",
            id: "booking delivered",
            value: 8765,
            dateYear: year,
            dateMonth: month - 1,
          },
          {
            name: "Stock",
            to: "#",
            id: "booking stock",
            value: 198765,
            dateYear: year,
            dateMonth: month - 1,
          },
          {
            name: "Stitching",
            to: "#",
            id: "booking stitching",
            value: 765,
            dateYear: year,
            dateMonth: month - 1,
          },
          {
            name: "Advance",
            to: "#",
            id: "booking advance",
            value: 76590,
            dateYear: year,
            dateMonth: month - 1,
          },
          {
            name: "Total",
            to: "#",
            id: "booking total",
            value: 76589665,
            dateYear: year,
            dateMonth: month - 1,
          },
        ],
      },
      {
        name: "Delivery",
        to: "#",
        id: "previous Month Delivery",
        icon: "bi bi-cash",
        subIcon: "bi bi-chevron-down",
        details: [
          {
            name: "ADIB",
            to: "#",
            id: "adib",
            value: 2234,
            dateYear: year,
            dateMonth: month - 1,
          },
          {
            name: "ATM",
            to: "#",
            id: "atm",
            value: 765,
            dateYear: year,
            dateMonth: month - 1,
          },
          {
            name: "CASH",
            to: "#",
            id: "cash",
            value: 8765,
            dateYear: year,
            dateMonth: month - 1,
          },

          {
            name: "Total",
            to: "#",
            id: "delivery total",
            value: 76589665,
            dateYear: year,
            dateMonth: month - 1,
          },
        ],
      },

      {
        name: "Expense",
        to: "#",
        id: "previous Month Expense",
        icon: "bi bi-layout-text-window-reverse",
        subIcon: "bi bi-chevron-down",
        details: [
          {
            name: "Piece-Expense",
            to: "#",
            id: "piece-expense",
            value: 2234,
            dateYear: year,
            dateMonth: month - 1,
          },
          {
            name: "Profit",
            to: "#",
            id: "profit",
            value: 8765,
            dateYear: year,
            dateMonth: month - 1,
          },
          {
            name: "Shop-Expense",
            to: "#",
            id: "shop-expense",
            value: 765,
            dateYear: year,
            dateMonth: month - 1,
          },

          {
            name: "Total",
            to: "#",
            id: "expense total",
            value: 76589665,
            dateYear: year,
            dateMonth: month - 1,
          },
        ],
      },
    ],
  },
  {
    name: "Total",
    to: "#",
    id: "total",
    icon: "bi bi-calendar-check",
    subIcon: "bi bi-chevron-down",
    subList: [
      {
        name: "Booking",
        to: "#",
        id: "Current year Booking",
        icon: "bi bi-bookmark",
        subIcon: "bi bi-chevron-down",
        details: [
          {
            name: "Cancel",
            to: "#",
            id: "total booking cancel",
            value: 2234,
            dateYear: null,
            dateMonth: null,
          },
          {
            name: "Credit",
            to: "#",
            id: "total booking credit",
            value: 765,
            dateYear: null,
            dateMonth: null,
          },
          {
            name: "Delivered",
            to: "#",
            id: "total booking delivered",
            value: 8765,
            dateYear: null,
            dateMonth: null,
          },
          {
            name: "Stock",
            to: "#",
            id: "total booking stock",
            value: 198765,
            dateYear: null,
            dateMonth: null,
          },
          {
            name: "Stitching",
            to: "#",
            id: "total booking stitching",
            value: 765,
            dateYear: null,
            dateMonth: null,
          },
          {
            name: "Advance",
            to: "#",
            id: "total booking advance",
            value: 76590,
            dateYear: null,
            dateMonth: null,
          },
          {
            name: "Total",
            to: "#",
            id: "total",
            value: 76589665,
            dateYear: null,
            dateMonth: null,
          },
        ],
      },
      {
        name: "Delivery",
        to: "#",
        id: "Current Year Delivery",
        icon: "bi bi-cash",
        subIcon: "bi bi-chevron-down",
        details: [
          {
            name: "ADIB",
            to: "#",
            id: "adib",
            value: 2234,
            dateYear: null,
            dateMonth: null,
          },
          {
            name: "ATM",
            to: "#",
            id: "atm",
            value: 765,
            dateYear: null,
            dateMonth: null,
          },
          {
            name: "CASH",
            to: "#",
            id: "cash",
            value: 8765,
            dateYear: null,
            dateMonth: null,
          },

          {
            name: "Total",
            to: "#",
            id: "delivery total",
            value: 76589665,
            dateYear: null,
            dateMonth: null,
          },
        ],
      },
      {
        name: "Expense",
        to: "#",
        id: "Current Year Expense",
        icon: "bi bi-layout-text-window-reverse",
        subIcon: "bi bi-chevron-down",
        details: [
          {
            name: "Piece-Expense",
            to: "#",
            id: "piece-expense",
            value: 2234,
            dateYear: null,
            dateMonth: null,
          },
          {
            name: "Profit",
            to: "#",
            id: "profit",
            value: 8765,
            dateYear: null,
            dateMonth: null,
          },
          {
            name: "Shop-Expense",
            to: "#",
            id: "shop-expense",
            value: 765,
            dateYear: null,
            dateMonth: null,
          },

          {
            name: "Total",
            to: "#",
            id: "expense total",
            value: 76589665,
            dateYear: null,
            dateMonth: null,
          },
        ],
      },
    ],
  },
  {
    name: "Logout",
    to: "#",
    id: "logout",
    icon: "bi bi-escape",
  },
];
export default list;
