import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import API from "./../../../services/API";
import { useAuthDispatch, useAuthState } from "../../../context/context";
import auth from "../../../constant";
import Loader from "./../../loder/Loader";
import moment from "moment";
const categories = [
  ["donation", "umar", "salman", "family", "customer service"],
  [
    "recharge",
    "salary",
    "stationary",
    "shop",
    "electric",
    "room",
    "loss",
    "cancel",
    "transport",
    "visa",	
  ],
  [
    "stitching",
    "folak",
    "fusoos",
    "fusoos-purchase",
    "khauwar",
    "tola",
    "khaka",
    "computer",
    "magribi",
    "qureshi",
    "talli",
    "altor",
    "out-statching",
    "sample",
    "material",
    "jheek",
    "delivery",
    "punching",
  ],
];
const AddExpense = ({ close }) => {
  //========================================last date records fethcing
  const state = useAuthState();
  const expenseDate = state?.expenseInfo[0]?.date;
  const dispatch = useAuthDispatch();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [expense, setExpense] = useState({
    amount: "",
    date: moment(expenseDate).format("YYYY-MM-DD") || "",
    dept: "profit",
    cat: "",
    name: "",
    remarks: "",
  });

  const handleExpense = (events) => {
    const { name, value } = events.target;
    setExpense({ ...expense, [name]: value });
  };

  const { amount, date, dept, cat, name, remarks } = expense;

  const expenseSubmit = async (e) => {
    e.preventDefault();
    try {
      //=====================================piece expense
      if (dept === "piece-expense") {
        if (
          cat === "TOLA" ||
          cat === "FUSOOS" ||
          cat === "FUSOOS-PURCHASE" ||
          cat === "STITCHING" ||
          cat === "FOLAK" ||
          cat === "KHAUWAR" ||
          cat === "KHAKA" ||
          cat === "COMPUTER" ||
          cat === "MAGRIBI" ||
          cat === "QURESHI" ||
          cat === "TALLI" ||
          cat === "ALTOR" ||
          cat === "OUT-STATCHING" ||
          cat === "SAMPLE" ||
          cat === "MATERIAL" ||
          cat === "JHEEK" ||
          cat === "PUNCHING" ||
          cat === "DELIVERY"
        ) {
          dispatch({ type: auth.expense.EXPENSE_REQUEST });
          const { data } = await API({
            method: "POST",
            url: "/api/auth-create-expense",
            data: { amount, date, dept, cat, name, remarks },
          });
          if (data?.success) {
            dispatch({ type: auth.expense.EXPENSE_SUCCESS });
            setSuccess(data?.message);
            setTimeout(() => {
              close();
              window.location.replace("/expense");
            }, 1000);
          }
        } else {
          setError(
            `${cat.toUpperCase()} is not right Category of ${dept.toUpperCase()} DEPARTMENT`
          );
        }
      }

      //==================================shop expense cat
      else if (dept === "shop-expense") {
        if (
          cat === "RECHARGE" ||
          cat === "SALARY" ||
          cat === "STATIONARY" ||
          cat === "SHOP" ||
          cat === "ELECTRIC" ||
          cat === "ROOM" ||
          cat === "LOSS" ||
          cat === "CANCEL" ||
          cat === "TRANSPORT" ||
	  cat === "VISA"
        ) {
          dispatch({ type: auth.expense.EXPENSE_REQUEST });
          const { data } = await API({
            method: "POST",
            url: "/api/auth-create-expense",
            data: { amount, date, dept, cat, name, remarks },
          });
          if (data?.success) {
            dispatch({ type: auth.expense.EXPENSE_SUCCESS });
            setSuccess(data?.message);
            setTimeout(() => {
              close();
              window.location.replace("/expense");
            }, 1000);
          }
        } else {
          setError(
            `${cat.toUpperCase()} is not right Category of ${dept.toUpperCase()} DEPARTMENT`
          );
        }
      }

      //===================================profit cat
      else if (dept === "profit") {
        if (
          cat === "DONATION" ||
          cat === "UMAR" ||
          cat === "SALMAN" ||
          cat === "FAMILY" ||
          cat === "CUSTOMER SERVICE"
        ) {
          dispatch({ type: auth.expense.EXPENSE_REQUEST });
          const { data } = await API({
            method: "POST",
            url: "/api/auth-create-expense",
            data: { amount, date, dept, cat, name, remarks },
          });
          if (data?.success) {
            dispatch({ type: auth.expense.EXPENSE_SUCCESS });
            setSuccess(data?.message);
            setTimeout(() => {
              close();
              window.location.replace("/expense");
            }, 1000);
          }
        } else {
          setError(
            `${cat.toUpperCase()} is not right Category of ${dept.toUpperCase()} DEPARTMENT`
          );
        }
      }
    } catch (error) {
      dispatch({ type: auth.ERROR });
      console.error(error);
    }
  };

  const expenseDepartment = [
    {
      name: "profit",
      value: "profit",
    },
    {
      name: "shop expense",
      value: "shop-expense",
    },

    {
      name: "piece expense",
      value: "piece-expense",
    },
  ];
  return (
    <>
      <div className="form-container">
        <div className="form-entry">
          <Form onSubmit={expenseSubmit}>
            <div className="input-margin-1">
              <Form.Group className="mb-3 d-flex-contanier">
                <Form.Label>
                  Amount
                  <span style={{ color: "red" }}>
                    <sub>&nbsp; *</sub>
                  </span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  value={amount}
                  className="first-child"
                  onChange={handleExpense}
                />
              </Form.Group>
              <Form.Group className="mb-3 d-flex-contanier">
                <Form.Label>
                  Date{" "}
                  <span style={{ color: "red" }}>
                    <sub>&nbsp; *</sub>
                  </span>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  value={date}
                  onChange={handleExpense}
                  className="last-child"
                />
              </Form.Group>
            </div>
            <Form.Group>
              <Form.Label>Department</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="dept"
                onChange={handleExpense}
              >
                {expenseDepartment?.map((v, i) => {
                  return (
                    <>
                      <option key={i} value={v.value}>
                        {v.name.toUpperCase()}
                      </option>
                    </>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <div className="input-margin">
              <Form.Group className="mb-3 d-flex-contanier">
                <Form.Label>
                  Category{" "}
                  <span style={{ color: "red" }}>
                    <sub>&nbsp; *</sub>
                  </span>
                </Form.Label>
                <Form.Control
                  list="datalistOptions"
                  id="exampleDataList"
                  placeholder="Search..."
                  name="cat"
                  value={cat}
                  onChange={handleExpense}
                  autoComplete="off"
                  className="first-child"
                />
                <datalist id="datalistOptions">
                  {expense.dept === "profit" || expense.dept === ""
                    ? categories[0].map((profit, key) => {
                        return (
                          <option
                            value={profit.toUpperCase()}
                            key={key}
                          ></option>
                        );
                      })
                    : null}
                  {expense.dept === "shop-expense"
                    ? categories[1].map((shopExpense, key) => {
                        return (
                          <option
                            value={shopExpense.toUpperCase()}
                            key={key}
                          ></option>
                        );
                      })
                    : null}
                  {expense.dept === "piece-expense"
                    ? categories[2].map((pieceExpense, key) => {
                        return (
                          <option
                            value={pieceExpense.toUpperCase()}
                            key={key}
                          ></option>
                        );
                      })
                    : null}
                </datalist>
              </Form.Group>

              <Form.Group className="mb-3 d-flex-contanier">
                <Form.Label>
                  Name{" "}
                  <span style={{ color: "red" }}>
                    <sub>&nbsp; *</sub>
                  </span>
                </Form.Label>
                <div className="password-testing">
                  <Form.Control
                    type="text"
                    name="name"
                    value={name}
                    onChange={handleExpense}
                    className="last-child"
                  />
                </div>
              </Form.Group>
            </div>
            <p style={{ color: "red" }}>{error}</p>
            <Form.Group>
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="remarks"
                value={remarks}
                onChange={handleExpense}
              />
            </Form.Group>

            <p style={{ color: "green" }}>{success}</p>
            <Stack>
              <Button
                variant="primary"
                size="sm"
                type="submit"
                className="btn-status"
              >
                {state?.loading ? <Loader /> : "Expense"}
              </Button>
            </Stack>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddExpense;
