import Sidebar from "../side-bar/Sidebar";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Search from "../header/search";
import { useEffect, useState } from "react";
import HomeLoader from "../loder/HomeLoader";
import { useAuthDispatch, useAuthState } from "../../context/context";
import auth from "../../constant";
import API from "../../services/API";
const Layout = ({ children }) => {
  const state = useAuthState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //===========================================================lookup data
  const dispatch = useAuthDispatch();
  //
  const lastDateRecord = async () => {
    try {
      dispatch({ type: auth.bookingInfo.BOOKING_INFO_REQUEST });
      const { data } = await API({
        method: "GET",
        url: "/api/booking-last-records",
      });
      if (data?.success) {
        const bookingInfo = data?.findLastDateRecords;
        dispatch({
          type: auth.bookingInfo.BOOKING_INFO_SUCCESS,
          payload: bookingInfo,
        });
      }

      const resDelivery = await API({
        method: "GET",
        url: "/api/delivery-last-records",
      });
      if (resDelivery?.data?.success) {
        const deliveryInfo = resDelivery?.data?.lastDateRecords;
        dispatch({
          type: auth.deliveryInfo.DELIVERY_INFO_SUCCESS,
          payload: deliveryInfo,
        });
      }

      const resExpense = await API({
        method: "GET",
        url: "/api/expense-last-records",
      });
      if (resExpense?.data?.success) {
        const expenseInfo = resExpense?.data?.lastDateRecords;
        dispatch({
          type: auth.expenseInfo.EXPENSE_INFO_SUCCESS,
          payload: expenseInfo,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRecords = async () => {
    const { data } = await API({
      method: "GET",
      url: "/api/booking-lookup",
    });
    if (data?.success) {
      const { booking } = data;
      localStorage.setItem("lookupData", JSON.stringify(booking));
    }
    //====================================expense records
    const res = await API({
      method: "GET",
      url: "/api/auth-all-expense-records",
    });
    if (res?.data?.success) {
      localStorage.setItem(
        "allExpenseData",
        JSON.stringify(res?.data?.records)
      );
    }
  };

  useEffect(() => {
    lastDateRecord();
    fetchRecords();
  }, []);

  return (
    <>
      {state?.fetchLoading ? (
        <HomeLoader />
      ) : (
        <Container fluid>
          <Sidebar />
          <div className="content-area">
            <Row>
              <div className="content-sub-area">
                {windowWidth <= 767 ? null : (
                  <Col className="col-12">
                    <Search />
                  </Col>
                )}
                <Col className="col-12">{children}</Col>
              </div>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
};

export default Layout;
