import moment from "moment";
const SearchByEmployeeName = ({ search }) => {
  let totalAmount = 0;
  const totalAmountBookingAndDelivery = [];
  const bookingRow = [];
  const searchEmployee = JSON.parse(localStorage.getItem("allExpenseData"));
  const employeeFilter = searchEmployee?.filter((v) => {
    if (v?.name.indexOf(search.toLowerCase()) !== -1) {
      totalAmount += v?.amount;
      return v?.name === search.toLowerCase();
    }
  });

  return (
    <>
      {employeeFilter?.length !== 0 ? (
        <div>
          <h6 className="delivery-info-details">Employee Info</h6>
          {employeeFilter?.map((element, key) => {
            return (
              <>
                <div className="total-booking-delivery" key={key}>
                  <div className="total-bookingcontainer">
                    <div className="total-property">Amount:-</div>
                    <div className="total-value">{element?.amount}</div>
                    <div className="total-property">Date:-</div>
                    <div className="total-value">
                      {moment(element?.date).format("DD/MM/YYYY")}
                    </div>
                    <div className="total-property">Department:-</div>
                    <div className="total-value">
                      {element?.dept.toUpperCase()}
                    </div>
                    <div className="total-property">Category:-</div>
                    <div className="total-value">{`${element?.cat.toUpperCase()}`}</div>
                    <div className="total-property">Name:-</div>
                    <div className="total-value">
                      {element?.name?.toUpperCase()}
                    </div>
                  </div>
                </div>
              </>
            );
          })}

          <br />
          <hr />
          <div className="total-booking-deliveries">
            <div className="total-footer-amount">
              <div className="total-p">Total Amount</div>
              <div className="total-v">{totalAmount}</div>
            </div>
          </div>
        </div>
      ) : (
        <p
          style={{ color: "red", textAlign: "center" }}
        >{`Oops No Records found from this Number (${search.toUpperCase()})`}</p>
      )}
    </>
  );
};

export default SearchByEmployeeName;
