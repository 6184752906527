import React from "react";
import list from "./index";
import SubList from "./SubList";
const List = () => {
  return (
    <>
      <ul className="side-bar-items">
        {list &&
          list.map((data) => {
            return (
              <SubList
                name={data.name}
                link={data.to}
                id={data.id}
                icon={data?.icon}
                SubList={data?.subList}
                subIcon={data?.subIcon}
              />
            );
          })}
      </ul>
    </>
  );
};

export default List;
