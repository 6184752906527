import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./css/style.css";
import Layout from "../layout/Layout";
import UpdateStatus from "../models/update/Update";
import TotalAmount from "./TotalAmount";
import moment from "moment";
import { useAuthState } from "../../context/context";
const Delivery = () => {
  const state = useAuthState();
  const totalDeliveryInfo = state?.deliveryInfo;


  //=====================================handle update booking
  const [value, setValue] = useState("");
  const [info, setInfo] = useState({});
  const [hide, setHide] = useState(false);
  const handleUpdateDelivery = ({
    _id,
    billNo,
    amount,
    amountType,
    qty,
    date,
  }) => {
    setInfo({
      _id,
      billNo,
      amount,
      amountType,
      qty,
      date,
    });
    setValue("Update Delivery");
    setHide(!hide);
  };
  const handleClose = () => {
    setHide(false);
  };

  return (
    <>
      <Layout>
        <div className="info-heading">
          <h1>Delivery Info</h1>
        </div>
        <div className="card-container">
          {totalDeliveryInfo?.map((data) => {
            const { _id, billNo, amount, amountType, qty, date } = data;
            return (
              <div className="card">
                <div className="card-items">
                  <div className="card-header">
                    <h5>
                      Bill No:- <span>{data.billNo}</span>
                    </h5>
                  </div>
                  <div className="card-body">
                    <ul>
                      <li>
                        <p>Amount</p>
                        <span>{data.amount}</span>
                      </li>
                      <li>
                        <p>Date</p>
                        <span>{moment(data.date).format("DD-MM-YYYY")}</span>
                      </li>
                      <li>
                        <p>Amount Status</p>
                        <span>{data.amountType.toUpperCase()}</span>
                      </li>
			 {data?.remarks && (
                        <li>
                          <p>Remarks</p>
                          <span>{data.remarks.toUpperCase()}</span>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="card-footer">
                    <Link
                      to="#"
                       onClick={() => handleUpdateDelivery({
                         _id,
                         billNo,
                         amount,
                         amountType,
                        qty,
                        date,
                      })}
                    >
                      Update Delivery
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <hr />
        <TotalAmount />
      </Layout>
      <UpdateStatus show={hide} value={value} close={handleClose} info={info} />
    </>
  );
};

export default Delivery;
