import auth from "../constant";
const token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;
export const initialState = {
  user: null,
  error: "",
  loading: false,
  token,
  floading: false,
  fetchLoading: false,
  info: false,
  bookingInfo : [],
  deliveryInfo: [],
  expenseInfo: []
};
export const AuthReducer = (state, action) => {
  switch (action.type) {
    case auth.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case auth.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.shopName,
        token: action.token,
        error: "",
        loading: false,
      };
    case auth.LGOUT:
      return {
        ...state,
        user: null,
        token: null,
      };
    case auth.ERROR:
      return {
        ...state,
        user: null,
        loading: false,
        fetchLoading: false,
        floading: false,
        error: action.payload,
      };
    case auth.booking.BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case auth.booking.BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case auth.delivery.DELIVERY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case auth.delivery.DELIVERY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case auth.expense.EXPENSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case auth.expense.EXPENSE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case auth.search.SEARCH_REAQUEST:
      return {
        ...state,
        loading: true,
      };
    case auth.search.SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case auth.submit.SUBMIT_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case auth.filter.FILTER_REQUEST:
      return {
        ...state,
        floading: true,
      };
    case auth.filter.FILTER_SUCCESS:
      return {
        ...state,
        floading: false,
      };
    case auth.fetch.FETCH_REQUEST:
      return {
        ...state,
        fetchLoading: true,
      };
    case auth.fetch.FETCH_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
      };
      case auth.bookingInfo.BOOKING_INFO_REQUEST :
        return {
          ...state, 
          info: true
        };
        case auth.bookingInfo.BOOKING_INFO_SUCCESS : 
        return {
          ...state,
          info: false,
          bookingInfo: action.payload,
        };
        case auth.deliveryInfo.DELIVERY_INFO_REQUEST : 
        return {
          ...state,
          info: true
        };
        case auth.deliveryInfo.DELIVERY_INFO_SUCCESS :
          return {
            ...state,
            info: false,
            deliveryInfo: action.payload
          };
          case auth.expenseInfo.EXPENSE_INFO_REQUEST : 
          return {
            ...state,
            info: true,
          };
          case auth.expenseInfo.EXPENSE_INFO_SUCCESS :
            return {
              ...state,
              info: false,
              expenseInfo: action.payload
            }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
