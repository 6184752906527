import React from "react";
import Modal from "react-bootstrap/Modal";
import BookingExplorer from "./BookingExplorer";
import DeliveryExplorer from "./DeliveryExplorer";
import ExpenseExplorer from "./ExpenseExplorer";
const monthName = [
  "January",
  "Februray",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const IndexStatus = ({ show, close, value, info, YYYY, MM }) => {
  if (
    value === "booking cancel" ||
    value === "booking credit" ||
    value === "booking stock" ||
    value === "booking stitching" ||
    value === "booking delivered" ||
    value === "booking total" ||
    value === "total booking cancel" ||
    value === "total booking cancel" ||
    value === "total booking credit" ||
    value === "total booking stock" ||
    value === "total booking stitching" ||
    value === "total booking delivered" ||
    value === "total"
  ) {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">
              {value.toUpperCase()} {YYYY === null ? "" : ` - ${YYYY}`}
              {MM === null ? "" : ` - ${monthName[MM]}`}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BookingExplorer
            status={info.toLowerCase()}
            close={close}
            year={YYYY}
            month={MM}
          />
        </Modal.Body>
      </Modal>
    );
  } else if (
    value === "adib" ||
    value === "atm" ||
    value === "cash" ||
    value === "delivery total"
  ) {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">
              {value.toUpperCase()} {YYYY === null ? "" : ` - ${YYYY}`}
              {MM === null ? "" : ` - ${monthName[MM]}`}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeliveryExplorer
            status={info.toLowerCase()}
            close={close}
            year={YYYY}
            month={MM}
          />
        </Modal.Body>
      </Modal>
    );
  } else if (
    value === "piece-expense" ||
    value === "shop-expense" ||
    value === "profit" ||
    value === "expense total"
  ) {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">
              {value.toUpperCase()} {YYYY === null ? "" : ` - ${YYYY}`}
              {MM === null ? "" : ` - ${monthName[MM]}`}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExpenseExplorer
            status={info.toLowerCase()}
            close={close}
            year={YYYY}
            month={MM}
          />
        </Modal.Body>
      </Modal>
    );
  }
};

export default IndexStatus;
