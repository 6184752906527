import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "./css/style.css";
import { userLogin } from "./../../context/action";
import { useAuthDispatch, useAuthState } from "../../context/context";
import Loader from "../loder/Loader";

const Signin = () => {
  const dispatch = useAuthDispatch();
  const state = useAuthState();
  const [eye, setEye] = useState(false);
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const inputHandler = (events) => {
    const { name, value } = events.target;
    setLogin({ ...login, [name]: value });
  };
  function clickOnEye() {
    setEye(!eye);
    const x = document.getElementById("password");

    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  const { email, password } = login;
  const handleLogin = async (e) => {
    e.preventDefault();
    let payload = { email, password };
    try {
      userLogin(dispatch, payload);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="auth-form-container">
      <div className="auth-form-sub-container">
        <div className="auth-signup-title">
          <h1>User Signin</h1>
        </div>
        <Form onSubmit={handleLogin}>
          <Form.Group className="mb-3 d-flex-contanier">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={email}
              onChange={inputHandler}
            />
          </Form.Group>
          <Form.Group className="mb-3 d-flex-contanier">
            <Form.Label>Password</Form.Label>
            <div className="password-testing">
              <Form.Control
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={inputHandler}
              />
              {password.length ? (
                <i
                  className={!eye ? `bi bi-eye-fill` : `bi bi-eye-slash-fill`}
                  onClick={clickOnEye}
                ></i>
              ) : null}
            </div>
          </Form.Group>
          <p className="error-message">{state?.error}</p>

          <Button type="submit" className="form-btn">
            {state?.loading ? <Loader /> : "Signin"}
          </Button>
        </Form>
        <div className="forget-tital">
          <Link to="#">Forgot Password</Link>
        </div>
        <div className="posintion-relative">
          <hr />
          <span>OR</span>
        </div>
        <div className="link-connect">
          <p>
            Register a user? <Link to="#">Signup</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signin;
