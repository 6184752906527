import React from "react";
import BookingInfo from "./BookingInfo";
import DeliveryInfo from "./DeliveryInfo";
import Modal from "react-bootstrap/Modal";
import ExpenseInfo from "./ExpenseInfo";
import AddDelivery from "../entry/AddDelivery";
import AddBooking from "../entry/AddBooking";
import AddExpense from "../entry/AddExpense";
import Logout from "./Logout";

const Status = ({ show, close, value }) => {
 const shopName = localStorage.getItem("shopName");
  if (value === "Filter Booking") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">{value.toUpperCase()}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BookingInfo />
        </Modal.Body>
      </Modal>
    );
  } else if (value === "Filter Delivery") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">{value.toUpperCase()}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeliveryInfo />
        </Modal.Body>
      </Modal>
    );
  } else if (value === "Filter Expense") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">{value.toUpperCase()}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExpenseInfo />
        </Modal.Body>
      </Modal>
    );
  } else if (value === "Delivery Entry") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">{value.toUpperCase()}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddDelivery close={close} />
        </Modal.Body>
      </Modal>
    );
  } else if (value === "Booking Entry") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">{value.toUpperCase()}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddBooking close={close} />
        </Modal.Body>
      </Modal>
    );
  } else if (value === "Expense Entry") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">{value.toUpperCase()}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddExpense close={close} />
        </Modal.Body>
      </Modal>
    );
  } else if (value === "logout") {
    return (
      <Modal show={show} onHide={close} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="model-status-info-details">{shopName.toUpperCase()}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Logout />
        </Modal.Body>
      </Modal>
    );
  }
};

export default Status;
