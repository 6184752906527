import { useLocation } from "react-router-dom";
import BookingSearch from "./searches/BookingSearch";
import DeliverySearch from "./searches/DeliverySearch";
import ExpenseSearch from "./searches/ExpenseSearch";
const Search = () => {
  const location = useLocation();
  const path = location?.pathname;
  if (path === "/") {
    return <BookingSearch />;
  } else if (path === "/delivery") {
    return <DeliverySearch />;
  } else if (path === "/expense") {
    return <ExpenseSearch />;
  }
};

export default Search;
