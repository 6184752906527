import React, { useState } from "react";
import "../update/css/style.css";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
const BookingExplorer = ({ status, year, month }) => {
  const shopName = localStorage.getItem("shopName");
  const totalAmountBookingAndDelivery = [];
  const bookingRow = [];
  const bookingExplorer = JSON.parse(localStorage.getItem("lookupData"));

  const filterByLookup = (bookingExplorer, term) => {
    if (bookingExplorer) {
      const filterStatus = bookingExplorer.filter((v, i) => {
        let tAmount = 0;
        if (v.status.indexOf(term) === -1) {
          for (let i of v.deliveryDetails) {
            if (v.billNo === i.billNo) {
              tAmount += i.amount;
            }
          }
        }
        if (
          (v.amount > tAmount && tAmount !== 0) ||
          v.deliveryDetails.length === 0
        ) {
          return true;
        } else return false;
      });
      let bookingTotal = 0;
      let deliveryTotal = 0;
      for (let i of filterStatus) {
        let date = new Date(i.date);

        //===========================conditional check
        if (
          (i.status === status &&
            date.getFullYear() === year &&
            month === null) ||
          (i.status === status &&
            date.getFullYear() === year &&
            date.getMonth() === month) ||
          (status === "total" &&
            date.getFullYear() === year &&
            month === null) ||
          (status === "total" &&
            date.getFullYear() === year &&
            date.getMonth() === month) ||
          (i.status === status && year === null && month === null) ||
          (status === "total" && year === null && month === null)
        ) {
          bookingTotal += i.amount;

          if (i?.deliveryDetails?.length > 0) {
            bookingRow.push(i);
            let deliveryTotalAmount = 0;
            for (let j of i.deliveryDetails) {
              deliveryTotalAmount += j.amount;
              deliveryTotal += j.amount;
            }
            i.deliveryTotal = deliveryTotalAmount;
          } else {
            bookingRow.push(i);
          }
        }
      }

      totalAmountBookingAndDelivery.push(bookingTotal);
      totalAmountBookingAndDelivery.push(deliveryTotal);
    }
  };

  filterByLookup(bookingExplorer, "cancel");
  const [exportToExcelRow, setExportToExcelRow] = useState([]);
  const [bookingExportTotal, setBookingExportTotal] = useState(0);
  const [deliveryExportTotal, setDeliveryExportTotal] = useState(0);
  function exportToExcel() {
    let bookingExportT = 0;
    let deliveryExportT = 0;
    const exportExcel =
      bookingRow &&
      bookingRow.map((element, key) => {
        bookingExportT += element?.amount;
        setBookingExportTotal(bookingExportT);

        if (element.deliveryDetails.length !== 0) {
          deliveryExportT += element.deliveryTotal;
          setDeliveryExportTotal(deliveryExportT);
          element.deliveryBillNo =
            element.deliveryDetails[element.deliveryDetails.length - 1]?.billNo;
          element.deliveryDate =
            element.deliveryDetails[element.deliveryDetails?.length - 1]?.date;

          element.deliveryAlert = element.amount - element.deliveryTotal;
        }
        return element;
      });
    setExportToExcelRow(exportExcel);
  }
  let totalRemaining = bookingExportTotal - deliveryExportTotal;

  return (
    <>
      {bookingRow?.length !== 0 ? (
        <>
          <div onClick={exportToExcel}>
            <ReactHTMLTableToExcel
              id={"test-table-xls-button"}
              className={"export-to-excel"}
              table={"table-to-xls"}
              filename={"tablexls"}
              sheet={"tablexls"}
              buttonText="Export to Excel"
            />
            <table
              id={"table-to-xls"}
              border={1}
              style={{ display: "none", fontFamily: "Times-New-Roman" }}
              className="excel-style"
            >
              <tr>
                <th
                  colSpan={11}
                  style={{
                    textAlign: "center",
                    backgroundColor: "darkblue",
                    color: "white",
                    height: "30px",
                    fontSize: "25px",
                    fontWeight: "600",
                  }}
                >
                  {shopName.toUpperCase()}
                </th>
              </tr>
              <tr>
                <th>B-Bill-No</th>
                <th>Name</th>
                <th>Date</th>
                <th style={{ width: "130px" }}>Phone</th>
                <th>Qty</th>
                <th>B-Amount</th>
                <th>Status</th>
                <th>D-Bill-No</th>
                <th>Delivery-Date</th>
                <th>Total Amount</th>
                <th>Alert</th>
              </tr>
              {exportToExcelRow.map((excel) => {
                const phone = excel.phone.toString();
                const code = excel.countryCode.toString();
                const contact = code + " " + phone;
                return (
                  <tr style={{ textAlign: "center" }}>
                    <td>{excel.billNo}</td>
                    <td style={{ textAlign: "left" }}>
                      {excel.name.toUpperCase()}
                    </td>
                    <td>{moment(excel.date).format("DD/MM/YYYY")}</td>
                    <td>{`${contact.toString()}`}</td>

                    <td>{excel.qty}</td>
                    <td>{excel.amount}</td>
                    <td>{excel.status.toUpperCase()}</td>
                    <td>{excel.deliveryBillNo}</td>
                    <td>
                      {excel.deliveryDate
                        ? moment(excel?.deliveryDate).format("DD/MM/YYYY")
                        : ""}
                    </td>
                    <td>{excel.deliveryTotal}</td>
                    <td style={{ color: "red" }}>
                      {excel?.deliveryAlert
                        ? excel?.deliveryAlert
                        : excel.amount}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <th
                  colSpan={10}
                  style={{
                    textAlign: "center"
                  }}
                >
                  Total
                </th>
                <th style={{ color: "red" }}>{totalRemaining}</th>
              </tr>
            </table>
          </div>
        </>
      ) : (
        ""
      )}

      {bookingRow?.length !== 0 ? (
        <div>
          {bookingRow &&
            bookingRow.map((element, key) => {
              return (
                <>
                  <div className="total-booking-delivery">
                    <h6 className="delivery-info-details">Booking Info</h6>
                    <div className="total-bookingcontainer" key={key}>
                      <div className="total-property">Bill No:-</div>
                      <div className="total-value">{element.billNo}</div>
                      <div className="total-property">Name:-</div>
                      <div className="total-value">
                        {element.name.toUpperCase()}
                      </div>
                      <div className="total-property">Phone:-</div>
                      <div className="total-value">{`${element.countryCode}-${element.phone}`}</div>
                      <div className="total-property">Date:-</div>
                      <div className="total-value">
                        {moment(element.date).format("DD/MM/YYYY")}
                      </div>
                      <div className="total-property">Quantity:-</div>
                      <div className="total-value">{element.qty}</div>
                      <div className="total-property">Amount:-</div>
                      <div className="total-value">{element.amount}</div>

                      {element?.advance ? (
                        <>
                          <div className="total-property">Advace:-</div>
                          <div className="total-value">{element.advance}</div>
                          <div className="total-property">Amount-Status:-</div>
                          <div className="total-value">
                            {element.amountType.toUpperCase()}
                          </div>
                        </>
                      ) : undefined}

                      <div className="total-property">Status:-</div>
                      <div className="total-value">
                        {element.status.toUpperCase()}
                      </div>
                      {element.deliveryDetails.length ? undefined : (
                        <>
                          {" "}
                          <div className="total-property alert-value">
                            Alert
                          </div>
                          <div className="total-value alert-value">
                            {element.amount}
                          </div>
                        </>
                      )}
                    </div>
                    {element.deliveryDetails.length ? <hr /> : undefined}
                    {element.deliveryDetails.length ? (
                      <h6 className="delivery-info-details">Delivery Info</h6>
                    ) : undefined}
                    {element.deliveryDetails.length
                      ? element.deliveryDetails.map((element, subKey) => {
                          return (
                            <>
                              <div
                                className="total-delivery-container"
                                key={subKey}
                              >
                                <div className="total-property">Bill No:-</div>
                                <div className="total-value">
                                  {element.billNo}
                                </div>
                                <div className="total-property">Date:-</div>
                                <div className="total-value">
                                  {moment(element.date).format("DD/MM/YYYY")}
                                </div>
                                <div className="total-property">Amount:-</div>
                                <div className="total-value">
                                  {element.amount}
                                </div>
                                <div className="total-property">
                                  Amount-Status:-
                                </div>
                                <div className="total-value">
                                  {element.amountType.toUpperCase()}
                                </div>
                              </div>
                            </>
                          );
                        })
                      : undefined}
                    {element?.deliveryDetails.length ? (
                      <div className="total-amount">
                        <div className="total-property">Total Booking</div>
                        <div className="total-value">{element.amount}</div>
                        <div className="total-property">Total Delivery</div>
                        <div className="total-value">
                          {element.deliveryTotal}
                        </div>
                        <div className="total-property alert-value">Alert</div>
                        <div className="total-value alert-value">
                          {element.amount - element.deliveryTotal}
                        </div>
                      </div>
                    ) : undefined}
                  </div>
                </>
              );
            })}
          <br />
          <hr />
          <div className="total-booking-deliveries">
            <div className="total-footer-amount">
              <div className="total-p">Total Booking</div>
              <div className="total-v">{totalAmountBookingAndDelivery[0]}</div>
              <div className="total-p">Total Delivery</div>
              <div className="total-v">{totalAmountBookingAndDelivery[1]}</div>
              <div className="total-p alert-value">Alert</div>
              <div className="total-v alert-value">
                {totalAmountBookingAndDelivery[0] -
                  totalAmountBookingAndDelivery[1]}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p
          style={{ color: "red", textAlign: "center" }}
        >{`Oops No Records found of this ${status.toUpperCase()} records!`}</p>
      )}
    </>
  );
};

export default BookingExplorer;
