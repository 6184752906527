import moment from "moment";
import React from "react";
const DeliveryExplorer = ({ year, status, month }) => {
  let total = 0;
  const deliveryInfo = [];
  const deliveryExplorer = JSON.parse(localStorage.getItem("lookupData"));
  const filterByLookup = (deliveryExplorer) => {
    const delivery = deliveryExplorer?.map((value) => {
      return value?.deliveryDetails;
    });
    const faltDelivery = delivery.flat();
    for (let i of faltDelivery) {
      let date = new Date(i.date);
      if (
        (i.amountType === status &&
          date.getFullYear() === year &&
          month === null) ||
        (i.amountType === status &&
          date.getFullYear() === year &&
          date.getMonth() === month) ||
        (status === "total" && date.getFullYear() === year && month === null) ||
        (status === "total" &&
          date.getFullYear() === year &&
          date.getMonth() === month) ||
        (i.amountType === status && year === null && month === null) ||
        (status === "total" && year === null && month === null)
      ) {
        deliveryInfo.push(i);
      }
    }
  };
  filterByLookup(deliveryExplorer);
  return (
    <>
      {deliveryInfo?.length !== 0 ? (
        <div>
          {deliveryInfo &&
            deliveryInfo.map((element, key) => {
              total += element.amount;
              return (
                <>
                  <div className="total-booking-delivery">
                    <h6 className="delivery-info-details">Delivery Info</h6>
                    <div className="total-bookingcontainer" key={key}>
                      <div className="total-property">Bill No:-</div>
                      <div className="total-value">{element.billNo}</div>
                      <div className="total-property">Date:-</div>
                      <div className="total-value">
                        {moment(element.date).format("DD/MM/YYYY")}
                      </div>

                      <div className="total-property">Amount:-</div>
                      <div className="total-value">{element.amount}</div>

                      <div className="total-property">Status:-</div>
                      <div className="total-value">
                        {element.amountType.toUpperCase()}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          <br />
          <hr />
          <div className="total-booking-deliveries">
            <div className="total-footer-amount">
              <div className="total-p">Total Amount</div>
              <div className="total-v">{total}</div>
            </div>
          </div>
        </div>
      ) : (
        <p
          style={{ color: "red", textAlign: "center" }}
        >{`Oops No Records found of this ${status.toUpperCase()} records!`}</p>
      )}
    </>
  );
};

export default DeliveryExplorer;
