import React from "react";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

const Logout = () => {
  const handleLogout = () => {
    localStorage.clear();
    window.location.replace("/signin");
  };
  return (
    <div>
      <p>Woluld you like to logout? </p>

      <Stack>
        <Button type="click" onClick={handleLogout}>
          Logout
        </Button>
      </Stack>
    </div>
  );
};

export default Logout;
