import moment from "moment";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import API from "../../../services/API";
import { useState } from "react";
import { useAuthDispatch, useAuthState } from "../../../context/context";
import auth from "../../../constant";
import Loader from "../../loder/Loader";
const DeleteDelivery = ({ deliveryInfo, close }) => {
  const dispatch = useAuthDispatch();
  const state = useAuthState();
  const [successMessage, setSuccessMessage] = useState("");
  const { _id, billNo, amount, amountType, qty, date } = deliveryInfo;
  const id = _id;

  const handleDelete = async (id) => {
    dispatch({ type: auth.filter.FILTER_REQUEST });
    try {
      const { data } = await API({
        method: "delete",
        url: "/api/delete-delivery",
        data: { id },
      });
      if (data?.success) {
        setSuccessMessage("Successfully Deleted!");
        dispatch({ type: auth.filter.FILTER_SUCCESS });
        setTimeout(close, 1000);
      }
    } catch (error) {
      dispatch({ type: auth.ERROR });
      console.error(error);
    }
  };
  return (
    <>
      <div className="delete-container">
        <div className="delete-sub-container">
          <h5 className="delete-info">Would you like to delete?</h5>
          <ul>
            <li className="delete-flex">
              <p>Bill-No</p>
              <span>{billNo}</span>
            </li>

            <li className="delete-flex">
              <p>Date</p>
              <span>{moment(date).format("DD/MM/YYYY")}</span>
            </li>

            <li className="delete-flex">
              <p>Quantity</p>
              <span>{`${qty}`}</span>
            </li>
            <li className="delete-flex">
              <p>Amount</p>
              <span>{`${amount}`}</span>
            </li>
            <li className="delete-flex">
              <p>Amount Status</p>
              <span>{`${amountType}`}</span>
            </li>
          </ul>
        </div>
      </div>
      <Stack>
        <p style={{ color: "green", textAlign: "center" }}>{successMessage}</p>
        <Button type="click" onClick={() => handleDelete(id)}>
          {state?.floading ? <Loader /> : "Deleted"}
        </Button>
      </Stack>
    </>
  );
};

export default DeleteDelivery;
