const auth = {
  LOGIN_REQUEST: "LOGIN-REQUEST",
  LOGIN_SUCCESS: "LOGIN-SUCCESS",
  LGOUT: "LOGOUT",
  ERROR: "ERROR",
  booking: {
    BOOKING_REQUEST: "BOOKING-REQUEST",
    BOOKING_SUCCESS: "BOOKING-SUCCESS",
  },
  delivery: {
    DELIVERY_REQUEST: "DELIVERY-REQUEST",
    DELIVERY_SUCCESS: "DELIVERY-SUCCESS",
  },
  expense: {
    EXPENSE_REQUEST: "EXPENSE-REQUEST",
    EXPENSE_SUCCESS: "EXPENSE-SUCCESS",
  },
  search: {
    SEARCH_REAQUEST: "SEARCH-REQUEST",
    SEARCH_SUCCESS: "SEARCH-SUCCESS",
  },
  submit: {
    SUBMIT_REQUEST: "SUBMIT-REQUEST",
    SUBMIT_SUCCESS: "SUBMIT-SUCCESS",
  },
  filter: {
    FILTER_REQUEST: "FILTER_REQUEST",
    FILTER_SUCCESS: "FILTER_SUCCESS",
  },
  fetch: {
    FETCH_REQUEST: "FETCH_REQUEST",
    FETCH_SUCCESS: "FETCH_SUCCESS",
  },
  bookingInfo : {
    BOOKING_INFO_REQUEST: "BOOKING_INFO_REQUEST",
    BOOKING_INFO_SUCCESS: "BOOKING_INFO_SUCCESS"
  },
  deliveryInfo : {
    DELIVERY_INFO_REQUEST: "DELIVERY_INFO_REQUEST",
    DELIVERY_INFO_SUCCESS: "ELIVERY_INFO_SUCCESS"
    },
    expenseInfo : {
      EXPENSE_INFO_REQUEST: "EXPENSE_INFO_REQUEST",
      EXPENSE_INFO_SUCCESS: "EXPENSE_INFO_SUCCESS"
    }

};
export default auth;
