import React, { useState } from "react";
import Layout from "../layout/Layout";
import "./css/style.css";
import UpdateStatus from "../models/update/Update";
import TotalAmount from "./TotalAmount";
import moment from "moment";
import { useAuthState } from "../../context/context";

const Booking = () => {
  const state = useAuthState();
  const bookingInfo = state.bookingInfo;
  const [value, setValue] = useState("");
  const [info, setInfo] = useState({});
  const [hide, setHide] = useState(false);
  const handleUpdateBooking = ({
    _id,
    billNo,
    name,
    date,
    phone,
    countryCode,
    qty,
    amount,
    status,
  }) => {
    setInfo({
      _id,
      billNo,
      name,
      date,
      phone,
      countryCode,
      qty,
      amount,
      status,
    });
    setValue("Update Booking");
    setHide(!hide);
  };

  //===================================
  const handleClose = () => {
    setHide(false);
  };
  return (
    <>
      <Layout>
        <div className="info-heading">
          <h1>Booking Info</h1>
        </div>

        <div className="card-container">
          {bookingInfo &&
            bookingInfo?.map((data) => {
              const {
                _id,
                billNo,
                name,
                date,
                phone,
                countryCode,
                qty,
                amount,
                advance,
                amountType,
                status,
              } = data;
              return (
                <div className="card">
                  <div className="card-items">
                    <div className="card-header">
                      <h5
                        onClick={() =>
                          handleUpdateBooking({
                            _id,
                            billNo,
                            name,
                            date,
                            phone,
                            countryCode,
                            qty,
                            amount,
                            status,
                          })
                        }
                      >
                        Bill No:- <span className="update-link">{billNo}</span>
                      </h5>
                    </div>
                    <div className="card-body">
                      <ul>
                        <li>
                          <p>Name</p>
                          <span>{name.toUpperCase()}</span>
                        </li>
                        <li>
                          <p>Phone</p>
                          <span>{phone}</span>
                        </li>
                        <li>
                          <p>Date</p>
                          <span>{moment(date).format("DD-MM-YYYY")}</span>
                        </li>
                        <li>
                          <p>Quantity</p>
                          <span>{qty}</span>
                        </li>
                        <li>
                          <p>Amount</p>
                          <span>{amount}</span>
                        </li>
                        {advance && (
                          <li>
                            <p>Advance</p>
                            <span>{advance}</span>
                          </li>
                        )}
                        {amountType && (
                          <li>
                            <p>Amount Status</p>
                            <span>{amountType.toUpperCase()}</span>
                          </li>
                        )}
                        <li>
                          <p>Piece Status</p>
                          <span>{status.toUpperCase()}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <hr />
        <TotalAmount />
      </Layout>
      <UpdateStatus show={hide} value={value} close={handleClose} info={info} />
    </>
  );
};

export default Booking;
