import auth from "../constant";
import API from "./../services/API";
export const userLogin = async (dispatch, payload) => {
  try {
    dispatch({ type: auth.LOGIN_REQUEST });
    const { data } = await API({
      url: "/api/auth-signin",
      method: "POST",
      data: payload,
    });

    if (data.success) {
      localStorage.setItem("test", "text");
      localStorage.setItem("shopName", data?.user?.address);
      dispatch({
        type: auth.LOGIN_SUCCESS,
        shopName: data.user?.address,
        token: data?.token,
      });
      localStorage.setItem("token", data?.token);
      window.location.replace("/");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: auth.ERROR, payload: error.response.data.message });
  }
};
export const authBooking = async (dispatch, payload) => {
  try {
    dispatch({ type: auth.booking.BOOKING_REQUEST });
    const { data } = await API({
      url: "/api/create-booking",
      method: "POST",
      data: payload,
    });
    if (data.success) {
      dispatch({ type: auth.booking.BOOKING_SUCCESS });
      window.location.replace("/");
    }
  } catch (error) {}
};
export const authDelivery = async (dispatch, payload) => {
  try {
    dispatch({ type: auth.delivery.DELIVERY_REQUEST });
    const { data } = await API({
      url: "/api/auth-create-delivery",
      method: "POST",
      data: payload,
    });
    if (data?.success) {
      console.log(data);
      // window.location.replace("/delivery");
    }
  } catch (error) {}
};
export const Logout = () => {};
