import React from "react";
import logo from "../../assests/logo.png";
const Logo = () => {
  return (
    <>
      <img src={logo} alt="logo" className="header-nav-logo" />
    </>
  );
};

export default Logo;
