import moment from "moment";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/esm/Stack";
import Form from "react-bootstrap/Form";
import "./css/style.css";
import { useAuthDispatch, useAuthState } from "../../../context/context";
import auth from "../../../constant";
import Loader from "../../loder/Loader";
import API from "../../../services/API";
const UpdateDelivery = ({ close, deliveryInfo, check }) => {
  const id = deliveryInfo._id;
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const [success, setSuccess] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [delivery, setDelivery] = useState({
    billNo: deliveryInfo.billNo,
    date: moment(deliveryInfo.date).format("YYYY-MM-DD"),
    amount: deliveryInfo.amount,
    qty: deliveryInfo.qty,
    amountType: deliveryInfo.amountType,
  });

  const deleryHandler = (events) => {
    const { name, value } = events.target;
    setDelivery({ ...delivery, [name]: value });
  };
  const { billNo, amount, amountType, qty, date } = delivery;
  const onSubmitForm = async (e) => {
    e.preventDefault();
    dispatch({ type: auth.delivery.DELIVERY_REQUEST });

    try {
      const { data } = await API({
        method: "PUT",
        url: "/api/update-delivery",
        data: { id, billNo, amount, amountType, qty, date },
      });
      if (data?.success) {
        setUpdateError("");
        dispatch({ type: auth.delivery.DELIVERY_SUCCESS });
        setSuccess(data?.message);
        if (check === "conditional refresh") {
          setTimeout(() => {
            close();
            window.location.replace("/delivery");
          }, 1000);
        }
        setTimeout(close, 1000);
      }
    } catch (error) {
      dispatch({ type: auth.ERROR });
      setUpdateError(error.response?.data?.message);
    }
  };
  const amountTypes = [
    {
      name: "cash",
      value: "cash",
    },
    {
      name: "atm",
      value: "atm",
    },
    {
      name: "adib",
      value: "adib",
    },
  ];

  return (
    <>
      <Form onSubmit={onSubmitForm}>
        <div className="form-container">
          <div className="form-entry">
            <Form.Group className="mb-3 d-flex-contanier">
              <Form.Label>
                Bill No{" "}
                <span style={{ color: "red" }}>
                  <sub>&nbsp; *</sub>
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                name="billNo"
                value={billNo}
                onChange={deleryHandler}
              />
            </Form.Group>
            <Form.Group className="mb-3 d-flex-contanier">
              <Form.Label>
                Date{" "}
                <span style={{ color: "red" }}>
                  <sub>&nbsp; *</sub>
                </span>
              </Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={date}
                onChange={deleryHandler}
              />
            </Form.Group>

            <Form.Group className="mb-3 d-flex-contanier">
              <Form.Label>
                Amount{" "}
                <span style={{ color: "red" }}>
                  <sub>&nbsp; *</sub>
                </span>
              </Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={amount}
                onChange={deleryHandler}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Amount Type</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="amountType"
                value={amountType}
                onChange={deleryHandler}
              >
                {amountTypes?.map((v, i) => {
                  return (
                    <>
                      <option key={i} value={v.value}>
                        {v.name.toUpperCase()}
                      </option>
                    </>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <p style={{ color: "green" }}>{success}</p>
            <p style={{ color: "red" }}>{updateError}</p>
            <Stack>
              <Button
                variant="primary"
                size="sm"
                type="submit"
                className="btn-status"
              >
                {state?.loading ? <Loader /> : "Updated Delivery"}
              </Button>
            </Stack>
          </div>
        </div>
      </Form>
    </>
  );
};

export default UpdateDelivery;
