import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import moment from "moment";
import { useState } from "react";
import { useAuthDispatch, useAuthState } from "../../../context/context";
import auth from "../../../constant";
import API from "../../../services/API";
import Loader from "../../loder/Loader";
const UpdateBooking = ({ close, bookingInfo }) => {
  const id = bookingInfo._id;
  const state = useAuthState();
  const [success, setSuccess] = useState("");
  const dispatch = useAuthDispatch();
  const [error, setError] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [bookingData, setBookingData] = useState({
    billNo: bookingInfo.billNo,
    name: bookingInfo.name,
    countryCode: bookingInfo.countryCode,
    phone: bookingInfo.phone,
    date: moment(bookingInfo.date).format("YYYY-MM-DD"),
    qty: bookingInfo.qty,
    amount: bookingInfo.amount,
    status: bookingInfo.status,
  });
  const bookingHandler = (events) => {
    if (
      events.target.name === "phone" ||
      events.target.name === "qty" ||
      events.target.name === "amount"
    ) {
      events.target.value = events.target.value.replace(/^0/, "");
    }
    const { name, value } = events.target;
    setBookingData({
      ...bookingData,
      [name]: value,
    });
  };
  const countryCodeDepartment = [
    {
      name: "UAE",
      value: "+971",
    },
    {
      name: "KSA",
      value: "+966",
    },
    {
      name: "Oman",
      value: "+968",
    },
    { name: "Kuwait", value: "+965" },
    { name: "Qatar", value: "+974" },
    { name: "Bahrain", value: "+973" },
  ];
  const faturaStatus = [
    { name: "stock", value: "stock" },
    { name: "cancel", value: "cancel" },
    { name: "stitching", value: "stitching" },
    { name: "delivered", value: "delivered" },
  ];

  const { billNo, name, countryCode, phone, date, qty, amount, status } =
    bookingData;

  const errorHandler = () => {
    if (countryCode === "+971" && phone?.length !== 9) {
      setError("Invalid UAE Number");
    } else if (countryCode === "+968" && phone?.length !== 8) {
      setError("Invalid Oman Number");
    } else if (countryCode === "+974" && phone?.length !== 8) {
      setError("Invalid Qatar Number");
    } else if (countryCode === "+965" && phone?.length !== 8) {
      setError("Invalid Kuwait Number");
    } else if (countryCode === "+973" && phone?.length !== 8) {
      setError("Invalid Bahrain Number");
    } else if (countryCode === "+966" && phone?.length !== 9) {
      setError("Invalid KSA Number");
    } else {
      setError("");
    }
  };
  const bookingUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: auth.booking.BOOKING_REQUEST });
      const { data } = await API({
        method: "PUT",
        url: "/api/update-booking",
        data: {
          id,
          billNo,
          name,
          countryCode,
          phone,
          date,
          qty,
          amount,
          status,
        },
      });
      if (data.success) {
        dispatch({ type: auth.booking.BOOKING_SUCCESS });
        setUpdateError("");
        setSuccess(data?.message);
        setTimeout(close, 1000);
      }
    } catch (error) {
      dispatch({ type: auth.ERROR });
      setUpdateError(error.response?.data?.message);
    }
  };
  return (
    <>
      <div className="form-container">
        <div className="form-entry">
          <Form onSubmit={bookingUpdateSubmit}>
            <div className="input-margin">
              <Form.Group className="mb-3 d-flex-contanier">
                <Form.Label>
                  Bill No
                  <span style={{ color: "red" }}>
                    <sub>&nbsp; *</sub>
                  </span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="billNo"
                  value={billNo}
                  onChange={bookingHandler}
                  readOnly
                  className="first-child"
                />
              </Form.Group>

              <Form.Group className="mb-3 d-flex-contanier">
                <Form.Label>
                  Name
                  <span style={{ color: "red" }}>
                    <sub>&nbsp; *</sub>
                  </span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={name}
                  onChange={bookingHandler}
                  required
                  className="last-child"
                />
              </Form.Group>
            </div>

            <Form.Group>
              <Form.Label>
                phone{" "}
                <span style={{ color: "red" }}>
                  <sub>&nbsp; *</sub>
                </span>
              </Form.Label>
              <div className="country-code">
                <Form.Select
                  aria-label="Default select example"
                  name="countryCode"
                  value={countryCode}
                  onChange={bookingHandler}
                >
                  {countryCodeDepartment?.map((v, i) => {
                    return (
                      <>
                        <option key={i} value={v.value}>
                          {v.name.toUpperCase()}
                        </option>
                      </>
                    );
                  })}
                </Form.Select>
                <Form.Control
                  type="number"
                  name="phone"
                  value={phone}
                  onChange={bookingHandler}
                  onBlur={errorHandler}
                  required
                />
              </div>
            </Form.Group>
            <span style={{ color: "red" }}>{error}</span>
            <Form.Group className="mb-3 d-flex-contanier">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={date}
                onChange={bookingHandler}
              />
            </Form.Group>
            <div className="input-margin">
              <Form.Group className="mb-3 d-flex-contanier">
                <Form.Label>
                  Qty{" "}
                  <span style={{ color: "red" }}>
                    <sub>&nbsp; *</sub>
                  </span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="qty"
                  value={qty}
                  onChange={bookingHandler}
                  required
                  className="first-child"
                />
              </Form.Group>
              <Form.Group className="mb-3 d-flex-contanier">
                <Form.Label>
                  Amount{" "}
                  <span style={{ color: "red" }}>
                    <sub>&nbsp; *</sub>
                  </span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  value={amount}
                  onChange={bookingHandler}
                  required
                  className="last-child"
                />
              </Form.Group>
            </div>

            <Form.Group className="mb-3 d-flex-contanier">
              <Form.Label>Status</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="status"
                value={status}
                onChange={bookingHandler}
              >
                {faturaStatus?.map((v, i) => {
                  return (
                    <>
                      <option key={i} value={v.value}>
                        {v.name.toUpperCase()}
                      </option>
                    </>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <p style={{ color: "green" }}>{success}</p>
            <p style={{ color: "red" }}>{updateError}</p>
            <Stack>
              <Button
                variant="primary"
                size="sm"
                type="submit"
                className="btn-status"
              >
                {state?.loading ? <Loader /> : "Updated Booking"}
              </Button>
            </Stack>
          </Form>
        </div>
      </div>
    </>
  );
};

export default UpdateBooking;
