import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { useState } from "react";
import API from "./../../../services/API";
import moment from "moment";
import { Link } from "react-router-dom";
import UpdateStatus from "../update/Update";
import Loader from "../../loder/Loader";
import { useAuthDispatch, useAuthState } from "../../../context/context";
import auth from "../../../constant";
function BookingInfo() {
  let totalAdvance = 0;
  const dispatch = useAuthDispatch();
  const state = useAuthState();
  let totalAmount = 0;
  const groupResults = [];
  const [records, setRecords] = useState([]);
  const [show, setShow] = useState(false);
  const [bookingFilter, setBookingFilter] = useState({
    startDate: "",
    lastDate: "",
    filterBooking: "",
  });
  const handleRange = (events) => {
    const { name, value } = events.target;
    setBookingFilter({ ...bookingFilter, [name]: value });
  };
  const { startDate, endDate, filterBooking } = bookingFilter;

//=====================================================bookingLookup
const filterData = JSON.parse(localStorage.getItem("lookupData"));
  async function handleRangeSubmit(e) {
    e.preventDefault();
   const fils = filterData?.filter((v)=> {
      const newStartDate = `${startDate}T00:00:00.000Z`;
      const newEndDate = `${endDate}T00:00:00.000Z`;
      if(v?.date >= newStartDate && v?.date <= newEndDate){
        // console.log("v", v)
        return v
      }
    
    })
    setRecords(fils)

  
  }
  const showInfo = () => {
    setShow(!show);
  };
  //===================================advance

  records?.map((v)=> {
    if(v?.advance !== undefined){
      totalAdvance += v?.advance;
    }
  })
  //=================================group total
  records &&
    records.reduce((res, value) => {
      if (!res[value?.status]) {
        res[value?.status] = { status: value?.status, amount: 0 };
        groupResults.push(res[value?.status]);
      }
      res[value?.status].amount += value.amount;
      return res;
    }, {});


  //============================================= deleting program
  const [value, setValue] = useState("");
  const [info, setInfo] = useState({});
  const [hide, setHide] = useState(false);
  const hanldeBookingDelete = ({
    _id,
    billNo,
    name,
    date,
    phone,
    countryCode,
    qty,
    amount,
    status,
  }) => {
    setInfo({
      _id,
      billNo,
      name,
      date,
      phone,
      countryCode,
      qty,
      amount,
      status,
    });
    setValue("Delete Booking");
    setHide(!hide);
  };

  const handleClose = () => {
    setHide(false);
  };
  //=====================================handle update booking
  const handleUpdateBooking = ({
    _id,
    billNo,
    name,
    date,
    phone,
    countryCode,
    qty,
    amount,
    status,
  }) => {
    setInfo({
      _id,
      billNo,
      name,
      date,
      phone,
      countryCode,
      qty,
      amount,
      status,
    });
    setValue("Update Booking");
    setHide(!hide);
  };
  return (
    <>
      <Form className="form-models" onSubmit={handleRangeSubmit}>
        <Form.Group className=" range-style" controlId="formBasicEmail">
          <Form.Control
            type="date"
            className="first-child"
            required
            name="startDate"
            onChange={handleRange}
            value={startDate}
          />
          <Form.Control
            type="date"
            className="last-child"
            required
            name="endDate"
            onChange={handleRange}
            value={endDate}
          />
        </Form.Group>
        <Form.Text className="text-muted">
          <p style={{ color: "red" }}>Select start-date and end-end</p>
        </Form.Text>

        {["radio"].map((type) => (
          <div key={`inline-${type}`} className="mb-3">
            <Form.Check
              inline
              label="Stock"
              value="stock"
              name="filterBooking"
              type={type}
              onChange={handleRange}
            />
            <Form.Check
              inline
              label="Cancel"
              value="cancel"
              name="filterBooking"
              type={type}
              onChange={handleRange}
            />
            <Form.Check
              inline
              label="Stitching"
              value="stitching"
              name="filterBooking"
              type={type}
              onChange={handleRange}
            />
            <Form.Check
              inline
              label="Credit"
              value="credit"
              name="filterBooking"
              type={type}
              onChange={handleRange}
            />
            <Form.Check
              inline
              label="Delivered"
              value="delivered"
              name="filterBooking"
              type={type}
              onChange={handleRange}
            />
            <Form.Check
              inline
              label="All"
              value=""
              name="filterBooking"
              type={type}
              onChange={handleRange}
            />
          </div>
        ))}
        <Stack>
          <Button
            variant="primary"
            size="sm"
            type="submit"
            className="btn-status"
          >
            {state?.floading ? <Loader /> : "Filter"}
          </Button>
        </Stack>
      </Form>
      {records.length !== 0 ? (
        <>
          <div className="info-container">
            <div className="icon-info">
              <i
                className={
                  show
                    ? "bi bi-chevron-up show-hide"
                    : "bi bi-chevron-down show-hide"
                }
                onClick={showInfo}
              ></i>
            </div>
            {show ? (
              <div className="mb-5">
                <h5 className="title-info">
                  Info &nbsp;<span>{`From ${startDate} To  ${endDate}`}</span>
                </h5>

                {records &&
                  records?.map((data) => {
                    const {
                      _id,
                      billNo,
                      name,
                      date,
                      phone,
                      countryCode,
                      qty,
                      amount,
                      status,
                    } = data;
                    return (
                      <div className="card-info">
                        <div className="card-items-info">
                          <div className="mt-1">
                            <div className="header-info">
                              <h5 className="card-heading-span">
                                Bill No:- <span>{billNo}</span>
                              </h5>
                              <Link
                                to="#"
                                onClick={() =>
                                  hanldeBookingDelete({
                                    _id,
                                    billNo,
                                    name,
                                    date,
                                    phone,
                                    countryCode,
                                    qty,
                                    amount,
                                    status,
                                  })
                                }
                              >
                                <i className="bi bi-trash3"></i>
                              </Link>
                            </div>
                          </div>
                          <div className="card-body">
                            <ul>
                              <li>
                                <p>Name</p>
                                <span>{name.toUpperCase()}</span>
                              </li>
                              <li>
                                <p>Phone</p>
                                <span>{`${countryCode}-${phone}`}</span>
                              </li>
                              <li>
                                <p>Date</p>
                                <span>{moment(date).format("DD/MM/YYYY")}</span>
                              </li>
                              <li>
                                <p>Quantity</p>
                                <span>{qty}</span>
                              </li>
                              <li>
                                <p>Amount</p>
                                <span>{amount}</span>
                              </li>
                              {data.advance && (
                                <li>
                                  <p>Advance</p>
                                  <span>{data.advance}</span>
                                </li>
                              )}
                              {data.amountStatus && (
                                <li>
                                  <p>Amount Status</p>
                                  <span>{data.amountStatus}</span>
                                </li>
                              )}
                              <li>
                                <p>Piece Status</p>
                                <span>{status.toUpperCase()}</span>
                              </li>
                            </ul>
                          </div>
                          <div className="card-info-footer">
                            <Link
                              to="#"
                              onClick={() =>
                                handleUpdateBooking({
                                  _id,
                                  billNo,
                                  name,
                                  date,
                                  phone,
                                  countryCode,
                                  qty,
                                  amount,
                                  status,
                                })
                              }
                            >
                              Update Booking
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <hr className="divider-info" />
                <div className="card-info">
                  <div className="card-items-info">
                    <div className="mt-1">
                      <h5 className="card-heading-span mb-4">
                        Total Booking Amount
                      </h5>
                      <div className="card-body">
                        <ul>
                          {groupResults &&
                            groupResults.map((total, key) => {
                              totalAmount += total.amount;
                              return (
                                <li>
                                  <p>{total.status.toUpperCase()}</p>
                                  <span>{total.amount}</span>
                                </li>
                              );
                            })}
                          <hr className="divider-info" />
                          <li>
                            <p className="card-heading-span">Total Advance</p>
                            <span className="card-heading-span">
                              {totalAdvance}
                            </span>
                          </li>
                          <li>
                            <p className="card-heading-span">Total Amount</p>
                            <span className="card-heading-span">
                              {totalAmount}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      <UpdateStatus show={hide} value={value} close={handleClose} info={info} />
    </>
  );
}

export default BookingInfo;
