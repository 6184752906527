import React, { useState } from "react";
import { Link } from "react-router-dom";
import DetailSublist from "./DetailSublist";
import Status from "../models/status/Status";
const SubList = ({ name, link, id, icon, SubList, subIcon }) => {
  const [hide, setHide] = useState(false);
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setHide(!hide);
  };
  const [value, setValue] = useState("");

  const handleShow = (item) => {
    setShow(!show);
    setValue(item);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <li className="nav-items">
        <div className="nav-items-child">
          <i className={`${icon} nav-icon`} />
          <Link to={link} key={id} onClick={() => handleShow(id)}>
            {name}
          </Link>
          <i className={`${subIcon} nav-drop-down`} onClick={handleClick} />
        </div>

        <ul className="nav-bar-sub-list">
          {SubList &&
            SubList.map((data) => {
              return (
                <>
                  {hide ? (
                    <li>
                      <div className="nav-items-child">
                        <i className={`${data.icon} nav-icon`} />
                        <Link to={data.to} onClick={() => handleShow(data.id)}>
                          {data?.name}
                        </Link>
                        <i className={`${data.subIcon} nav-drop-down`} />
                      </div>
                      <ul>
                        <DetailSublist details={data?.details} show={show} />
                      </ul>
                    </li>
                  ) : null}
                </>
              );
            })}
        </ul>
      </li>
      <Status show={show} value={value} close={handleClose} />
    </>
  );
};

export default SubList;
