import { Link } from "react-router-dom";
import IndexStatus from "../models/explore-details/Index";
import { useState } from "react";
const DetailSublist = ({ details, show }) => {
  const [hide, setHide] = useState(false);
  const [value, setValue] = useState("");
  const [info, setInfo] = useState("");
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const handleValue = ({ name, id, dateYear, dateMonth }) => {
    setValue(id);
    setInfo(name);
    setHide(true);
    setYear(dateYear);
    setMonth(dateMonth);
  };
  const handleClose = () => {
    setHide(false);
  };
  return (
    <>
      {details &&
        details.map((info) => {
          const { name, id, dateYear, dateMonth } = info;
          return (
            <li onClick={() => handleValue({ name, id, dateYear, dateMonth })}>
              <div className="nav-items-child details">
                {show ? (
                  <>
                    <Link to={info.to} className="details-info">
                      {info?.name}
                    </Link>
                    {/* <span className="details-sublist-info">{info.value}</span> */}
                    <span className="details-sublist-info">{""}</span>
                  </>
                ) : null}
              </div>
            </li>
          );
        })}
      <IndexStatus
        show={hide}
        value={value}
        close={handleClose}
        info={info}
        YYYY={year}
        MM={month}
      />
    </>
  );
};

export default DetailSublist;
