import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import moment from "moment";
import { useState } from "react";

import API from "../../../services/API";
import { useAuthDispatch, useAuthState } from "./../../../context/context";
import auth from "../../../constant";
import Loader from "./../../loder/Loader";

const AddBooking = ({ close }) => {
  const [errorBillNo, setErrorBillNo] = useState("");
  const [advanceError, setAdvanceError] = useState("");
  const [check, setCheck] = useState(false);
  const [readyMade, setReadyMade] = useState(false);
  const [success, setSuccess] = useState("");
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const [error, setError] = useState("");
  const booking = state.bookingInfo;
  const lastBillNo = booking ? booking[0] : "";
  const [bookingData, setBookingData] = useState({
    billNo: lastBillNo ? Number(lastBillNo.billNo) + 1 : "",
    name: "",
    countryCode: "+971",
    phone: "",
    date: lastBillNo ? moment(lastBillNo?.date).format("YYYY-MM-DD") : "",
    qty: "",
    amount: "",
    advance: "",
    amountType: "cash",
    status: "stock",
  });
  const bookingHandler = (events) => {
    if (events.target.name === "phone" || events.target.name === "qty") {
      events.target.value = events.target.value.replace(/^0/, "");
    }

    const { name, value } = events.target;
    setBookingData({
      ...bookingData,
      [name]: value,
    });
  };
  const [readyMadeState, setReadyMadeState] = useState({
    billNo: "",
    name: "",
    countryCode: "+971",
    phone: "",
    date: lastBillNo ? moment(lastBillNo?.date).format("YYYY-MM-DD") : "",
    qty: "",
    amount: "",
    amountType: "cash",
    status: "ready-made",
  });

  const readyMadeHandler = (events) => {
    const { name, value } = events.target;
    setReadyMadeState({
      ...readyMadeState,
      [name]: value,
    });
  };
  const countryCodeDepartment = [
    {
      name: "UAE",
      value: "+971",
    },
    {
      name: "KSA",
      value: "+966",
    },
    {
      name: "Oman",
      value: "+968",
    },
    { name: "Kuwait", value: "+965" },
    { name: "Qatar", value: "+974" },
    { name: "Bahrain", value: "+973" },
  ];
  const faturaStatus = [
    { name: "stock", value: "stock" },
    { name: "cancel", value: "cancel" },
    { name: "stitching", value: "stitching" },
  ];

  const amountStatus = [
    {
      name: "CASH",
      value: "cash",
    },
    {
      name: "ADIB",
      value: "ADIB",
    },
    {
      name: "ATM",
      value: "atm",
    },
  ];

  const {
    billNo,
    name,
    countryCode,
    phone,
    date,
    qty,
    amount,
    advance,
    amountType,
    status,
  } = bookingData;

 const errorHandler = () => {
    if (readyMade) {
      if (
        readyMadeState.countryCode === "+971" &&
        readyMadeState.phone?.length !== 9
      ) {
        setError("Invalid UAE Number");
      } else if (
        readyMadeState.countryCode === "+968" &&
        readyMadeState.phone?.length !== 8
      ) {
        setError("Invalid Oman Number");
      } else if (
        readyMadeState.countryCode === "+974" &&
        readyMadeState.phone?.length !== 8
      ) {
        setError("Invalid Qatar Number");
      } else if (
        readyMadeState.countryCode === "+965" &&
        readyMadeState.phone?.length !== 8
      ) {
        setError("Invalid Kuwait Number");
      } else if (
        readyMadeState.countryCode === "+973" &&
        readyMadeState.phone?.length !== 8
      ) {
        setError("Invalid Bahrain Number");
      } else if (
        readyMadeState.countryCode === "+966" &&
        readyMadeState.phone?.length !== 9
      ) {
        setError("Invalid KSA Number");
      } else {
        setError("");
      }
    } else {
      if (countryCode === "+971" && phone?.length !== 9) {
        setError("Invalid UAE Number");
      } else if (countryCode === "+968" && phone?.length !== 8) {
        setError("Invalid Oman Number");
      } else if (countryCode === "+974" && phone?.length !== 8) {
        setError("Invalid Qatar Number");
      } else if (countryCode === "+965" && phone?.length !== 8) {
        setError("Invalid Kuwait Number");
      } else if (countryCode === "+973" && phone?.length !== 8) {
        setError("Invalid Bahrain Number");
      } else if (countryCode === "+966" && phone?.length !== 9) {
        setError("Invalid KSA Number");
      } else {
        setError("");
      }
    }
  };
  const bookingSubmit = async (e) => {
    if (check === true && advance === "") {
      setAdvanceError("Advance amount Field is required");
    } else {
      e.preventDefault();
      try {
        dispatch({ type: auth.booking.BOOKING_REQUEST });
        if (readyMade) {
          const { data } = await API({
            method: "POST",
            url: "/api/create-booking",
            data: {
              billNo: readyMadeState.billNo,
              name: readyMadeState.name,
              countryCode: readyMadeState.countryCode,
              phone: readyMadeState.phone,
              date: readyMadeState.date,
              qty: readyMadeState.qty,
              amount: readyMadeState.amount,
              status: readyMadeState.status,
              amountType: readyMadeState.amountType,
            },
          });
          if (data.success) {
            dispatch({ type: auth.booking.BOOKING_SUCCESS });
            setSuccess(data?.message);
            setTimeout(() => {
              window.location.reload("/");
              close();
            }, 1000);
          }
        } else {
          const { data } = await API({
            method: "POST",
            url: "/api/create-booking",
            data: {
              billNo,
              name,
              countryCode,
              phone,
              date,
              qty,
              amount,
              advance,
              amountType,
              status,
            },
          });
          if (data.success) {
            dispatch({ type: auth.booking.BOOKING_SUCCESS });
            setSuccess(data?.message);
            setTimeout(() => {
              window.location.reload("/");
              close();
            }, 1000);
          }
        }
      } catch (error) {
        dispatch({ type: auth.ERROR });
        setErrorBillNo("Bill No Already Exists");
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="form-container">
        <div className="form-entry">
          <Form onSubmit={bookingSubmit}>
            {!readyMade ? (
              <>
                <div className="input-margin">
                  <Form.Group className="mb-3 d-flex-contanier">
                    <Form.Label>
                      Bill No
                      <span style={{ color: "red" }}>
                        <sub>&nbsp; *</sub>
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="billNo"
                      value={billNo}
                      onChange={bookingHandler}
                      // readOnly
                      className="first-child"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 d-flex-contanier">
                    <Form.Label>
                      Name
                      <span style={{ color: "red" }}>
                        <sub>&nbsp; *</sub>
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={name}
                      onChange={bookingHandler}
                      required
                      className="last-child"
                    />
                  </Form.Group>
                </div>
                <p style={{ color: "red" }}>{errorBillNo ? errorBillNo : ""}</p>
                <Form.Group>
                  <Form.Label>
                    phone{" "}
                    <span style={{ color: "red" }}>
                      <sub>&nbsp; *</sub>
                    </span>
                  </Form.Label>
                  <div className="country-code">
                    <Form.Select
                      aria-label="Default select example"
                      name="countryCode"
                      value={countryCode}
                      onChange={bookingHandler}
                    >
                      {countryCodeDepartment?.map((v, i) => {
                        return (
                          <>
                            <option key={i} value={v.value}>
                              {v.name.toUpperCase()}
                            </option>
                          </>
                        );
                      })}
                    </Form.Select>
                    <Form.Control
                      type="number"
                      name="phone"
                      value={phone}
                      onChange={bookingHandler}
                      onBlur={errorHandler}
                      required
                    />
                  </div>
                </Form.Group>
                <span style={{ color: "red" }}>{error}</span>
                <Form.Group className="mb-3 d-flex-contanier">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={date}
                    onChange={bookingHandler}
                  />
                </Form.Group>
                <div className="input-margin">
                  <Form.Group className="mb-3 d-flex-contanier">
                    <Form.Label>
                      Qty{" "}
                      <span style={{ color: "red" }}>
                        <sub>&nbsp; *</sub>
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="qty"
                      value={qty}
                      onChange={bookingHandler}
                      required
                      className="first-child"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 d-flex-contanier">
                    <Form.Label>
                      Amount{" "}
                      <span style={{ color: "red" }}>
                        <sub>&nbsp; *</sub>
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="amount"
                      value={amount}
                      onChange={bookingHandler}
                      required
                      className="last-child"
                    />
                  </Form.Group>
                </div>
                {check && (
                  <>
                    <div className="advance-style">
                      <Form.Group>
                        <Form.Label>
                          Advance{" "}
                          <span style={{ color: "red" }}>
                            <sub>&nbsp; *</sub>
                          </span>
                        </Form.Label>

                        <Form.Control
                          type="number"
                          name="advance"
                          value={advance}
                          onChange={bookingHandler}
                          onBlur={errorHandler}
                          className="advance-child-first"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          Amount Status{" "}
                          <span style={{ color: "red" }}>
                            <sub>&nbsp; *</sub>
                          </span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="amountType"
                          value={amountType}
                          onChange={bookingHandler}
                          className="advance-child-last"
                        >
                          {amountStatus?.map((v, i) => {
                            return (
                              <>
                                <option key={i} value={v.value}>
                                  {v.name.toUpperCase()}
                                </option>
                              </>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </>
                )}
                <span style={{ color: "red" }}>{advanceError}</span>
                <Form.Group className="mb-3 d-flex-contanier">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="status"
                    value={status}
                    onChange={bookingHandler}
                  >
                    {faturaStatus?.map((v, i) => {
                      return (
                        <>
                          <option key={i} value={v.value}>
                            {v.name.toUpperCase()}
                          </option>
                        </>
                      );
                    })}
                  </Form.Select>
                </Form.Group>{" "}
              </>
            ) : (
              <>
                <div className="input-margin">
                  <Form.Group className="mb-3 d-flex-contanier">
                    <Form.Label>
                      Model No
                      <span style={{ color: "red" }}>
                        <sub>&nbsp; *</sub>
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="billNo"
                      value={readyMadeState.billNo}
                      onChange={readyMadeHandler}
                      // readOnly
                      className="first-child"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 d-flex-contanier">
                    <Form.Label>
                      Name
                      <span style={{ color: "red" }}>
                        <sub>&nbsp; *</sub>
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={readyMadeState.name}
                      onChange={readyMadeHandler}
                      required
                      className="last-child"
                    />
                  </Form.Group>
                </div>
                <p style={{ color: "red" }}>{errorBillNo ? errorBillNo : ""}</p>
                <Form.Group>
                  <Form.Label>
                    phone{" "}
                    <span style={{ color: "red" }}>
                      <sub>&nbsp; *</sub>
                    </span>
                  </Form.Label>
                  <div className="country-code">
                    <Form.Select
                      aria-label="Default select example"
                      name="countryCode"
                      value={readyMadeState.countryCode}
                      onChange={readyMadeHandler}
                    >
                      {countryCodeDepartment?.map((v, i) => {
                        return (
                          <>
                            <option key={i} value={v.value}>
                              {v.name.toUpperCase()}
                            </option>
                          </>
                        );
                      })}
                    </Form.Select>
                    <Form.Control
                      type="number"
                      name="phone"
                      value={readyMadeState.phone}
                      onChange={readyMadeHandler}
                      onBlur={errorHandler}
                      required
                    />
                  </div>
                </Form.Group>
                <span style={{ color: "red" }}>{error}</span>
                <Form.Group className="mb-3 d-flex-contanier">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={readyMadeState.date}
                    onChange={readyMadeHandler}
                  />
                </Form.Group>
                <div className="input-margin">
                  <Form.Group className="mb-3 d-flex-contanier">
                    <Form.Label>
                      Qty{" "}
                      <span style={{ color: "red" }}>
                        <sub>&nbsp; *</sub>
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="qty"
                      value={readyMadeState.qty}
                      onChange={readyMadeHandler}
                      required
                      className="first-child"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 d-flex-contanier">
                    <Form.Label>
                      Amount{" "}
                      <span style={{ color: "red" }}>
                        <sub>&nbsp; *</sub>
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="amount"
                      value={readyMadeState.amount}
                      onChange={readyMadeHandler}
                      required
                      className="last-child"
                    />
                  </Form.Group>
                </div>
                <span style={{ color: "red" }}>{advanceError}</span>
                <Form.Group>
                  <Form.Label>
                    Amount Status{" "}
                    <span style={{ color: "red" }}>
                      <sub>&nbsp; *</sub>
                    </span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="amountType"
                    value={readyMadeState.amountType}
                    onChange={readyMadeHandler}
                    className="advance-child-last"
                  >
                    {amountStatus?.map((v, i) => {
                      return (
                        <>
                          <option key={i} value={v.value}>
                            {v.name.toUpperCase()}
                          </option>
                        </>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </>
            )}
            <p style={{ color: "green" }}>{success}</p>
            <div className="d-flex justify-content-between">
              {readyMade ? (
                ""
              ) : (
                <Form.Check
                  inline
                  label="Advance"
                  name="group1"
                  type={"checkbox"}
                  className="mb-3"
                  onChange={(e) => setCheck(e.target.checked)}
                />
              )}

              <Form.Check
                inline
                label="Ready-Made"
                name="ready-made"
                type={"checkbox"}
                className="mb-3"
                onChange={(e) => setReadyMade(e.target.checked)}
              />
            </div>
            <Stack>
              <Button
                variant="primary"
                size="sm"
                type="submit"
                className="btn-status"
              >
                {state?.loading ? <Loader /> : "Booked"}
              </Button>
            </Stack>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddBooking;
